.mainpage {
  display: flex;
  background-color: #F2F2F2;
  height: 100vh;
  width: 100vw;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  z-index: 999;
  .img-wrapper{
    width: auto;
    height: 100%;
    align-self: flex-start;
    .authImg{
      width: 100%;
      height: 100%;
      //width: 43.5%;
      //height: 96%;
      border-radius: 30px;
      left: 30px;
    }
  }
  .main-form-wrapper {
    width: 400px;
    height: 800px;
    margin-left: 10vw;
    z-index: 222;
  }
}
.verify {
  display: flex;
  background-color: #F2F2F2;
  height: 100vh;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 999;
  padding: 30px 30px 30px 140px;
  box-sizing: border-box;
  .img-wrapper {
    width: 835px;
    height: 100%;
    .verify-img {
      width: 100%;
      height: 100%;
      //width: 43.5%;
      //height: 96%;
      border-radius: 30px;
      left: 30px;
    }
  }
}
.confirmated, .disable-auth, .enable-auth{
  display: flex;
  background-color: #F2F2F2;
  height: 100vh;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 999;
  padding: 30px 30px 30px 140px;
  box-sizing: border-box;
  .text-wrapper {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
      color: var(--main-palette-dino-dark, #2A2B3B);
      font-family: MacPawFixelMedium;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      b{
        color: #7E9D00;
      }
    }
    span{
      color: var(--grey-50, #95959D);
      text-align: center;
      font-family: MacPawFixelMedium;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      b{
        color: #7E9D00;
        cursor: pointer;
        font-family: MacPawFixelBold;
      }
    }
    .two-factor-input-box{
      display: flex;
      gap: 5px;
      margin: 30px 0;
      input{
        width: 30px;
        height: 40px;
        padding: 10px;
        box-sizing: border-box;
        border: none;
        border-radius: 10px;
        outline: none;
        &:after {
          outline: 1px solid #7E9D00; // Darken the outline color on active
        }
      }
    }
    button {
      display: flex;
      margin: 20px 0 20px;
      cursor: pointer;
      width: 205px;
      background: #7E9D00;
      box-sizing: border-box;
      height: 44px;
      border: none;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      border-radius: 10px;
      div {
        color: #F2F2F2;
        font-family: MacPawFixelMedium;
      }
      :disabled {
        div {
          opacity: 0.5;
        }
      }
    }
    button:hover {
      opacity: 0.8;
    }
  }
  .img-wrapper {
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    .confirmated-img {
      width: 100%;
      height: 100%;
      //width: 43.5%;
      //height: 96%;
      border-radius: 30px;
      left: 30px;
    }
  }
}
.forgot-pass {
  display: flex;
  background-color: #F2F2F2;
  height: 100vh;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 999;
  padding: 30px 30px 30px 140px;
  box-sizing: border-box;
  .img-wrapper {
    width: 835px;
    height: 100%;
    left: 30px;
    .authImg {
      width: 100%;
      height: 100%;
      //width: 43.5%;
      //height: 96%;
      border-radius: 30px;
      left: 30px;
    }
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 400px;
    margin-top: 20px;
    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      width: 400px;
      margin-top: 20px;
      .email {
        display: flex;
        flex-direction: column;
        width: 100%;
        .email-span{
          font-family: MacPawFixelMedium;
          color: #2A2B3B;
          font-size: 14px;
          margin: 5px 0 5px 20px;
        }
        .email-field {
          width: 100%;
          padding: 10px 20px;
          box-sizing: border-box;
          font-size: 14px;
          border: 1.5px solid #D4D5D8;
          border-radius: 10px;
          font-family: MacPawFixelMedium;
          line-height: 25px;
          outline: none;
        }
        .email-input::placeholder {
          font-family: MacPawFixelRegular;
          font-size: 14px;
          opacity: 0.3;
        }
      }
      .sign-in {
        display: flex;
        margin: 20px 0 20px;
        cursor: pointer;
        width: 100%;
        background: #7E9D00;
        box-sizing: border-box;
        height: 44px;
        border: none;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border-radius: 10px;
        div {
          color: #F2F2F2;
          font-family: MacPawFixelMedium;
        }
        :disabled {
          div {
            opacity: 0.5;
          }
        }
      }
      .sign-in:hover {
        opacity: 0.8;
      }
      .sign-disabled {
        opacity: 0.5;
        display: flex;
        padding: 10px 20px;
        height: 44px;
        box-sizing: border-box;
        margin: 20px 0 20px;
        cursor: pointer;
        width: 100%;
        background: #C7C7C7;
        border: none;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        div {
          color: #F2F2F2;
          font-family: MacPawFixelMedium;
        }
      }
    }
  }
}
