.header {
  width: 100vw;
  box-sizing: border-box;
  left: 0;
  top: 0;
  height: 80px;
  padding: 20px 30px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;

  .content-box {
    align-self: center;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    .route-status {
      display: flex;
      align-items: center;

      .manual-routes {
        display: flex;
        align-items: baseline;
        font-size: 16px;
        font-family: MacpawFixelMedium;

        & span {
          display: flex;
          cursor: pointer;
          margin: 0 0 0 10px;
          color: #2a2b3b;
        }

        & p {
          margin: 0 10px;
          color: #7e9d00;
        }
      }

      & img {
        width: 18px;
        height: 18px;
      }
    }
    
    .header-btns-group {
      display: flex;
      align-items: center;
      
      .undo-redo-group {
        border: 1px solid var(--border-color);
        border-radius: 5px;
        padding: 9px;
        margin: 0 10px;
        
        & svg:not(:first-child) {
          margin: 0 0 0 30px;
        }
      }
      .save-btn {
        background: var(--dino-green-bg-default);
        color: var(--dino-white);
        &:hover {
          background: var(--dino-green-bg-hover);
          color: var(--dino-white);
        }
      }
    }
  }
  
  /* BackToEditIconButton styles */
  .header-icon-btn-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .media-query-btn-wrapper {
      width: 60px;
      border: 1px solid var(--border-color);
      border-radius: 8px;
      padding: 8px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      caret-color: transparent;
      margin: 0 23% 0 auto;
    }
    
    .preview-btn-wrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      caret-color: transparent;
    }
  }
}

.test-header{
  width: 100vw;
  box-sizing: border-box;
  left: 0;
  top: 0;
  height: 80px;
  padding: 20px 10vw 20px 18.4vw;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  z-index: 21;
  
  .content-box {
    align-self: center;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    
    .route-status {
      display: flex;
      align-items: center;
      
      .manual-routes {
        display: flex;
        align-items: baseline;
        font-size: 16px;
        font-family: MacpawFixelMedium;
        
        & span {
          display: flex;
          cursor: pointer;
          margin: 0 0 0 10px;
          color: #2a2b3b;
        }
        
        & p {
          margin: 0 10px;
          color: #2a2b3b;
        }
      }
      
      & img {
        width: 18px;
        height: 18px;
      }
    }
    
    .btns {
      display: flex;
      justify-content: flex-start;
      margin-left: 30px;
      
      .group {
        display: flex;
      }
      .outline {
        display: flex;
        height: 44px;
        padding: 10px 20px;
        background-color: transparent;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        margin-left: 10px;
        cursor: pointer;
        align-items: center;
        justify-content: space-evenly;
        
        & span {
          font-family: MacPawFixelMedium;
          color: #2a2b3b;
          font-size: 14px;
          margin-left: 5px;
        }
        
        & img {
          width: 24px;
          height: 24px;
        }
        
        &.prev {
          margin-left: 0;
          border-right-width: 0;
          padding-right: 10px;
        }
        
        &.next {
          margin-left: 0;
          border-left-width: 0;
          padding-left: 10px;
        }
      }
      
      .filled {
        cursor: pointer;
        width: 205px;
        height: 44px;
        background-color: #7e9d00;
        border: none;
        border-radius: 10px;
        margin-left: 10px;
        
        & span {
          font-family: MacPawFixelMedium;
          color: #ffffff;
          font-size: 14px;
        }
      }
      
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
}
