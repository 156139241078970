.dropdown-container {
  text-align: left;
  border: none;
  position: relative;
  border-radius: 10px;
  width: 440px;
  .dropdown-input {
    padding: 0 20px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    background-color: #fff;
    border-radius: 10px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .dropdown-menu {
    position: absolute;
    transform: translateY(6px);
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(42, 43, 59, 0.1);
    border-radius: 10px;
    overflow: auto;
    max-height: 150px;
    background-color: #fff;
    z-index: 99;
    min-height: 320px;
  }
}
.dropdown-item {
  display: flex;
  height: 20px;
  flex-direction: column;
  align-items: center;
  height: 45px;
  margin: 0px 10px;
  padding: 20px 10px 0px;
  cursor: pointer;
  justify-content: space-between;
  .content {
    display: flex;
    align-self: flex-start;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
    }
    span {
      font-family: MacpawFixelMedium;
      font-size: 16px;
      margin-left: 10px;
      line-height: 18px;
    }
  }
  hr {
    width: 100%;
    border: 1px solid #000000;
    opacity: 0.1;
    margin: 10px 0 0;
  }
}

.dropdown-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.dropdown-tag-item {
  background-color: #ddd;
  padding: 2px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}
.dropdown-tag-close {
  display: flex;
  align-items: center;
}
.search-box {
  padding: 5px;
  background-color: #eee;
}
.search-box input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
