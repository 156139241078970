.cam-modal-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2222;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .cam-modal{
    width: 680px;
    height: 400px;
    border-radius: 20px;
    background: var(--Main-palette-White, #FAFAFA);
    position: relative;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .cam-title{
      .modal-title{
        margin: 0;
        font-family: MacPawFixelBold, "sans-serif";
        color: var(--Main-palette-Dino_Dark, #2A2B3B);
        font-size: 24px;
        font-weight: 700;
      }
      .modal-title-text{
        color: var(--Grey-70, #6A6B76);
        text-align: center;
        font-family: MacPawFixelMedium, 'sans-serif';
        font-size: 14px;
        font-weight: 500;
      }
      .close-btn{
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #EAEAEC;
        position: absolute;
        top: 10px;
        right: 10px;
        .close-modal{
          width: 15px;
          height: 15px;
        }
      }
    }
    .cam-modal-cards{
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .modal-card{
        padding: 30px;
        box-sizing: border-box;
        border-radius: 20px;
        position: relative;
        border: 1px solid transparent; /* Add a transparent border by default */
        transition: border-color 0.3s;
        .star-icon{
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .modal-main-img{
          margin-bottom: 30px;
        }
        span{
          color: var(--Main-palette-Dino_Dark, #2A2B3B);
          font-family: MacPawFixelBold, "sans-serif";
          font-size: 16px;
          font-weight: 700;
        }
        &:hover {
          border: 1px solid var(--hover-color);
        }

        &:hover {
          --hover-color: var(--Main-palette-White); // Default hover color, change it to your default border color
        }

        // Set unique hover colors for each card
        &:hover[data-hover="#7E9D00"] {
          --hover-color: #7E9D00; // Color from MODAL_CARDS[0].hover
        }

        &:hover[data-hover="#666DA5"] {
          --hover-color: #666DA5; // Color from MODAL_CARDS[1].hover
        }

        &:hover[data-hover="#D36700"] {
          --hover-color: #D36700; // Color from MODAL_CARDS[2].hover
        }
      }
    }
  }
}
