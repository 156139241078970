.upload-campaign {
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
  left: 0;
  display: flex;
  position: fixed;
  align-items: center;
  overflow: hidden;
  padding: 20px 55px 20px 18.4vw;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  height: 170px;
  top: 79px;
  justify-content: flex-start;
  background-color: #ffffff;
  .cards {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    gap: 15px;
    width: 100%;
    .email{
      border: 1px solid rgba(42, 43, 59, 0.3);
      padding: 20px;
      width: 33.33%;
      height: 81px;
      border-radius: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      .upload-des {
        margin-left: 10px;
        width: 230px;
        text-align: left;
        h4 {
          font-size: 14px;
          font-family: MacPawFixelBold;
          margin: 0;
        }
        p {
          opacity: 0.5;
          font-size: 12px;
          font-family: MacPawFixelMedium;
          margin: 0;
          text-overflow: ellipsis;
        }
      }
      img {
        width: 24px;
        height: 24px;
        align-self: start;
      }
    }
    .journey, .abTesting{
      width: 33.33%;
      height: 81px;
      border: 1px solid rgba(42, 43, 59, 0.3);
      padding: 20px;
      border-radius: 20px;
      cursor: default;
      box-sizing: border-box;
      align-items: center;
      display: flex;
      position: relative;
      opacity: 0.5;
      .cooming-soon {
        position: absolute;
        align-items: center;
        width: 55px;
        top: 0;
        right: 0;
        border-radius: 0px 20px;
        background: #e4e4e6;
        span {
          padding-bottom: 5%;
          color: #2a2b3b;
          font-size: 12px;
          font-family: MacPawFixelMedium;
          opacity: 0.5;
        }
      }
      .upload-des {
        text-align: left;
        margin-left: 10px;
        width: 230px;
        opacity: 0.7;
        h4 {
          font-size: 14px;
          font-family: MacPawFixelBold;
          margin: 0;
        }
        p {
          font-size: 12px;
          font-family: MacPawFixelMedium;
          margin: 0;
        }
      }
      img {
        width: 24px;
        height: 24px;
        align-self: start;
      }
    }
  }
}
