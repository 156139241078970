.upload-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
    left: 0;
    top: 0;
    border-bottom: 1px solid #e8e8e8;
    height: 80px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    .content-box {
      align-self: center;
      max-width: 1755px;
      margin: 0px 0px 0px 6vw;
      width: 70vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .route-status {
        display: flex;
        align-items: center;
        .routes {
          display: flex;
          margin: 2% 0px 0px 10px;
          align-items: baseline;
          .prev-route {
            display: flex;
            font-size: 16px;
            margin-right: 5px;
            font-family: MacpawFixelMedium;
            cursor: pointer;
          }
          .curr-route {
            margin: 0;
            color: #7e9d00;
          }
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
      .btns {
        display: flex;
        margin-left: 20px;
        .transparent {
          width: 156px;
          height: 44px;
          background-color: transparent;
          border: 1px solid #7e9d00;
          border-radius: 10px;
          cursor: pointer;
          span {
            font-family: MacPawFixelMedium;
            color: #7e9d00;
            font-size: 14px;
          }
        }
        .filled {
          cursor: pointer;
          width: 156px;
          height: 44px;
          background-color: #7e9d00;
          border: none;
          border-radius: 10px;
          margin-left: 10px;
          span {
            font-family: MacPawFixelMedium;
            color: #ffffff;
            font-size: 14px;
          }
        }
        :disabled {
          opacity: 0.5;
        }
      }
      .btns-hide {
        display: none;
      }
    }
  }
  .pg-bars {
    top: 80px;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: baseline;
    .progress-bar-upload {
      font-family: MacpawFixelBold;
      text-align: left;
      width: 85%;
      margin: 0px 0px 0px 14vw;
      display: flex;
      flex-direction: column;
      height: 10px;
      h1 {
        margin: 0;
        font-size: 24px;
      }
      .progress-wrapper {
        .parent-prog-bar {
          height: 2px !important;
        }
        .child-prog-bar {
          height: 2px !important;
        }
      }
    }
  }
  .inner-content {
    margin: 12vh 0 0 18vw;
    width: 55vw;
    height: 100%;
    .count-upload {
      min-width: 1030px;
      display: flex;
      justify-content: space-between;
      .title-count {
        align-items: center;
        font-family: MacpawFixelBold;
        display: flex;
        span {
          opacity: 0.5;
          font-size: 32px;
        }
        p {
          margin: 0 0 0 8px;
          font-size: 32px;
        }
      }
      .btns {
        display: flex;
        margin-left: 30px;
        .transparent {
          width: 156px;
          height: 44px;
          background-color: transparent;
          border: 1px solid #7e9d00;
          border-radius: 10px;
          cursor: pointer;
          span {
            font-family: MacPawFixelMedium;
            color: #7e9d00;
            font-size: 14px;
          }
        }
        .filled {
          cursor: pointer;
          width: 156px;
          height: 44px;
          background-color: #7e9d00;
          border: none;
          border-radius: 10px;
          margin-left: 10px;
          span {
            font-family: MacPawFixelMedium;
            color: #ffffff;
            font-size: 14px;
          }
        }
        :disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }
    .uploader-box {
      width: 100%;
      min-width: 1030px;
      display: flex;
      margin-top: 30px;
      .uploader {
        width: 675px;
        height: 320px;
        section {
          width: 100%;
          height: 100%;
          border: 1px dashed #7e9d00;
          border-radius: 6.72269px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative;
          .modal-hide {
            display: none;
          }
          .modal-upload {
            position: fixed;
            background: rgba(0, 0, 0, 0.8);
            backdrop-filter: blur(20px);
            z-index: 2;
            width: 100vw;
            height: 100vh;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .modal-window {
              background-color: #ffffff;
              width: 28vw;
              height: 16vh;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 20px;
              border-radius: 10px;
              h1 {
                margin: 0 0 0px;
                align-self: center;
                max-width: 258px;
                font-size: 20px;
                font-family: MacpawFixelBold;
              }
              span {
                font-family: MacpawFixelMedium;
                font-size: 12px;
              }
              .btns {
                display: flex;
                justify-content: space-evenly;
                button {
                  width: 161px;
                  height: 42px;
                  font-family: MacPawFixelMedium;
                  font-size: 14px;
                }
                .bnt1 {
                  border: 1px solid #7e9d00;
                  border-radius: 10px;
                  background-color: transparent;
                  span {
                    color: #7e9d00;
                    font-size: 14px;
                  }
                }
                .bnt2 {
                  border: none;
                  border-radius: 10px;
                  background-color: #ff5656;
                  span {
                    color: #ffffff;
                    font-size: 14px;
                  }
                }
              }
            }
          }
          .dropzone {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 150px;
            .options-hide {
              display: none;
            }
            .options {
              margin-top: 20px;
              p {
                font-size: 18px;
                line-height: 15px;
                font-family: MacPawFixelMedium;
                cursor: default;
                a {
                  text-decoration: underline;
                  cursor: pointer;
                  color: #7e9d00;
                }
              }
              span {
                margin-top: 20px;
                line-height: 24.84px;
                font-size: 18px;
                font-family: MacPawFixelBold;
              }
            }
            img {
              width: 68px;
              height: 60px;
            }
          }
          aside {
            .item-name {
              width: 100%;
              height: 53px;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
              }
              button {
                width: 133px;
                height: 39px;
                border: none;
                display: flex;
                justify-content: space-between;
                padding: 10px 10px 10px 20px;
                align-items: center;
                background: #2a2b3b;
                border-radius: 10px;
                cursor: pointer;
                span {
                  color: #ffffff;
                }
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
            .item-hide {
              display: none;
            }
          }
        }
      }
    }
  }
}
