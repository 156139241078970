.lang-stat-wrapper{
  width: 100%;
  padding: 30px;
  border-radius: 20px;
  background: #FAFAFA;
  .lang-title{
    color: var(--Main-palette-Dino_Dark, #2A2B3B);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: MacPawFixelSemiBold, 'sans-serif';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 26.4px */
    text-align: left;
    margin-bottom: 35px;
  }
  .lang-name{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    align-items: center;
    .name-box{
      display: flex;
      align-items: center;
      .title-text{
        margin-left: 10px;
        color: var(--Main-palette-Dino_Dark, #2A2B3B);
        font-family: MacPawFixelMedium, 'sans-serif';
        font-size: 12px;
        font-weight: 500;
        line-height: 110%; /* 13.2px */
      }
    }
    .lang-value{
      color: var(--Main-palette-Dino_Dark, #2A2B3B);
      font-family: MacPawFixelBold, "sans-serif";
      font-size: 12px;
      font-weight: 700;
      line-height: 110%; /* 13.2px */
    }
  }
}