.time-bar-chart {
  text-align: center;
  margin-top: 30px;
  position: relative;
  .time-chart-title{
    position: absolute;
    top: 25px;
    left: 30px;
    color: var(--Main-palette-Dino_Dark, #2A2B3B);
    font-family: MacPawFixelBold, "sans-serif";
    font-size: 16px;
    font-weight: 700;
    line-height: 110%; /* 17.6px */
  }
  .custom-dropdown{
    position: absolute;
    top: 20px;
    left: 140px;
    border-radius: 10px;
    border: 1.5px solid #BFCE80;
    display: flex;
    width: 150px;
    height: 32px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    .selected-option{
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .placeholder{
        color: var(--Main-palette-Dino_Dark, #2A2B3B);
        font-family: MacPawFixelMedium, 'sans-serif';
        font-size: 14px;
      }
      img{
        width: 24px;
        height: 24px;
      }
    }
    .options {
      overflow-y: auto;
      list-style: none;
      padding: 0;
      margin: 7px 0 0;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 1.5px solid #D4D5D8;
      border-radius: 10px;
      background: #FFF;
      display: flex;
      flex-direction: column;
      li {
        align-items: flex-start;
        text-align: left;
        padding: 10px 20px;
        cursor: pointer;
        color: #95959D;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
  .buttons {
    position: absolute;
    top: 0;
    right: 20px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
    .day-btn {
      display: flex;
      width: 54px;
      height: 24px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 6px;
      background: var(--Main-palette-Green, #F4F4F5);
      border: none;
      div{
        color: #2a2b3b;
      }
    }
    .current-day{
      background: #7e9d00;
      div{
        color: #FFFFFF;
      }
    }
  }
  .highcharts-axis-labels.highcharts-yaxis-labels{
    //y-axis
    display: none;
  }
}
