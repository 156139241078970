.app-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  right: 0;
  background: rgba(0, 0, 0, 0.80);
  backdrop-filter: blur(20px);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
  animation: fadeIn .25s ease-in-out;
  &.open {
    display: flex;
  }
  & .modal-window {
    width: 440px;
    height: auto;
    border-radius: 20px;
    background: #FFF;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
  }
}
