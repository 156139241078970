.upload-manually {
  position: relative;
  width: 100%;
  .header {
    width: 100%;
    left: 0;
    top: 0;
    border-bottom: 1px solid #e8e8e8;
    height: 80px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    .content-box {
      align-self: center;
      max-width: 1755px;
      margin: 0px 0px 0px 6vw;
      width: 70vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .route-status {
        display: flex;
        align-items: center;
        .manual-routes {
          display: flex;
          align-items: baseline;
          span {
            display: flex;
            cursor: pointer;
            font-size: 16px;
            font-family: MacpawFixelMedium;
            margin: 2% 0px 0px 10px;
          }
          p {
            margin: 0 10px;
            color: #7e9d00;
          }
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
      .btns {
        display: flex;
        margin-left: 30px;
        .transparent {
          width: 156px;
          height: 44px;
          background-color: transparent;
          border: 1px solid #7e9d00;
          border-radius: 10px;
          cursor: pointer;
          span {
            font-family: MacPawFixelMedium;
            color: #7e9d00;
            font-size: 14px;
          }
        }
        .filled {
          cursor: pointer;
          width: 156px;
          height: 44px;
          background-color: #7e9d00;
          border: none;
          border-radius: 10px;
          margin-left: 10px;
          span {
            font-family: MacPawFixelMedium;
            color: #ffffff;
            font-size: 14px;
          }
        }
        :disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }
  }
  .pg-bars {
    top: 82px;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: baseline;
    .progress-bar-upload {
      font-family: MacpawFixelBold;
      text-align: left;
      width: 90%;
      margin: 0px 0px 0px 14vw;
      display: flex;
      flex-direction: column;
      h1 {
        margin: 0;
        font-size: 24px;
      }
      .progress-wrapper {
        .parent-prog-bar {
          height: 2px !important;
        }
        .child-prog-bar {
          height: 2px !important;
        }
      }
    }
  }
  .inner-content {
    margin: 12vh 0 0 18vw;
    width: 55vw;
    height: 100%;

    .count-upload {
      margin-top: 30px;
      min-width: 1030px;
      display: flex;
      justify-content: space-between;
      .title-count {
        flex-direction: column;
        align-items: center;
        font-family: MacpawFixelMedium;
        display: flex;
        span {
          align-self: flex-start;
          font-size: 16px;
          margin-top: 30px;
        }
        p {
          align-self: flex-start;
          margin: 0 0 0 0px;
          font-size: 14px;
          opacity: 0.7;
        }
        .main-text {
          font-size: 32px;
          font-family: MacpawFixelBold;
          margin: 0px 0px 30px;
          span {
            font-size: 32px;
            color: rgba(42, 43, 59, 0.3);
          }
        }
      }
    }
    .pg-bars {
      width: 100%;
      min-width: 1030px;
      display: flex;
      align-items: baseline;
      .progress-bar-upload {
        font-family: MacpawFixelBold;
        text-align: left;
        width: 50%;
        margin: 0px 10px 0px 0px;
        display: flex;
        flex-direction: column;
        height: 47px;
        h1 {
          margin: 0;
          font-size: 24px;
        }
        .progress-wrapper {
          .parent-prog-bar {
            height: 6px !important;
          }
          .child-prog-bar {
            height: 6px !important;
          }
        }
      }
      .progress-bar-segmentation {
        font-family: MacpawFixelBold;
        text-align: left;
        display: flex;
        flex-direction: column;
        height: 47px;
        justify-content: center;
        width: 50%;
        margin: 0px 10px 0px 0px;
        h1 {
          margin: 0;
          font-size: 16px;
          margin-bottom: 4px;
        }
        .progress-wrapper {
          .parent-prog-bar {
            height: 6px !important;
          }
          .child-prog-bar {
            height: 6px !important;
          }
        }
      }
    }
    .area-wrapper {
      position: relative;
      width: 100%;
      min-width: 1030px;
      height: 205px;
      display: flex;
      .vl {
        width: 20px;
        padding: 0px 0 0 0;
        display: flex;
        flex-direction: column;
        position: fixed;
        height: 205px;
        align-self: flex-start;
        overflow-y: auto;
        font-size: 12px;
        line-height: 1.6em;
        z-index: 22;
        border-right: 1px solid #d8d8d8;
      }
      .vl::-webkit-scrollbar {
        display: none;
      }
      .textarea {
        width: 100%;
        height: 100%;
        font-family: MacPawFixelMedium;
        resize: none;
        white-space: nowrap;
        border: none;
        border-radius: 4px;
        font-size: 12px;
        outline: none;
        line-height: 1.6em;
        padding: 0px 0 0px 25px;
        background-image: linear-gradient(#ffffff 50%, #f1f1f1 50%);
        background-size: 100% 38px;
      }
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        padding: 3px;
      }

      ::-webkit-scrollbar-track {
        padding: 3px;
      }
      ::-webkit-scrollbar-track {
        border: 1px solid #f2f2f2;
        border-right: #ffffff;
        padding: 3px;
      }
      ::-webkit-scrollbar-corner {
        display: none;
        border: #ffffff;
      }
      ::-webkit-scrollbar-thumb {
        width: 3px;
        padding: 3px;
        border-radius: 6px;
        background-color: #c9c9c9;
      }
    }
    .text-field {
      min-width: 1030px;
      position: relative;
      margin-top: 20px;
      .vl {
        border-right: 1px solid rgb(211, 211, 211);
        height: 91%;
        max-height: 100%;
        position: absolute;
        border-radius: 10px 0 0 10px;
        background-color: #ffffff;
        left: 0%;
        width: 30px;
        margin: 0 0 0 1%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-self: center;
        transform: translateY(-100%);
        flex-direction: column;
        padding-top: 10px;
        span {
          width: 100%;
          font-size: 12px;
          line-height: 17px;
        }
      }
      textarea {
        left: 0;
        height: 200px;
        outline: none;
        overflow-x: auto;
        align-self: flex-start;
        border-radius: 0px 10px 10px 0px;
        border: none;
        font-family: MacpawFixelMedium;
        font-size: 12px;
        width: 980px;
        max-width: 1030px;
        padding: 10px 0 0 25px;
        height: 100%;
        resize: none;
        box-sizing: border-box;
        white-space: nowrap;
      }
    }
  }
}
