.audience-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.audience-list-item {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 20px;
  background: var(--dino-white);
  &:hover {
    cursor: pointer;
  }
  .audience-list-item_title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    span {
      color: var(--dino-dark);
      font-family: MacPawFixelBold;
      font-size: 24px;
      font-weight: 700;
      line-height: 110%; /* 26.4px */
    }
    img {}
  }
  .audience-list-item_stats {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .audience-list-item_stats__item {
      display: flex;
      flex: 1;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-radius: 10px;
      background: var(--dino-grey-10);
      img {}
      span {
        overflow: hidden;
        color: var(--dino-dark);
        text-overflow: ellipsis;
        font-family: MacPawFixelMedium;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .audience-list-item_date {
    color: var(--dino-dark);
    font-family: MacPawFixelMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 110%; /* 15.4px */
  }
}

.audience-add-new-list {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;
  background: url('../../../../assets/images/audienceAddNewListBg.png') no-repeat center center;
  background-size: cover;
  &:hover {
    cursor: pointer;
  }
}

.audience-list-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.audience-list-modal-header {
  color: var(--dino-dark);
  font-family: MacPawFixelBold;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.audience-list-modal-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  span {
    color: var(--dino-dark);
    font-family: MacPawFixelMedium;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
  }
  input {
    display: flex;
    box-sizing: border-box;
    padding: 10px 20px;
    width: 100%;
    height: 48px;
    border-radius: 10px;
    border: 1px solid var(--dino-grey-10);
    background: var(--dino-white);
    color: var(--Main-palette-Dino_Dark, #2A2B3B);
    font-family: MacPawFixelMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    &::placeholder {
      color: var(--dino-grey-40);
    }
  }
}

.audience-list-modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  button {
    flex: 1;
    border-radius: 10px;
    border: 0;
    font-family: MacPawFixelMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
  .cancel-btn {
    background: var(--dino-grey-10);
    color: var(--dino-dark);
  }
  .save-btn {
    background: var(--dino-green);
    color: var(--dino-white);
  }
}

