:root {
  --custom-color: var(--dino-dark);
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: MacPaw Fixel, MacPawFixelMedium, sans-serif;
  color: var(--custom-color);
  caret-color: transparent;
}

p.title {
  font-size: 14px;
  caret-color: transparent;
}
