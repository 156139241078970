.campaigns-tab-wrapper{
  .campaigns-tab-placeholder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    img{
      width: 140px;
      height: 140px;
    }
    .sub-title{
      margin-top: 30px;
      color: var(--Main-palette-Dino_Dark, #2A2B3B);
      text-align: center;
      font-family: MacPawFixelBold, "sans-serif";
      font-size: 24px;
      font-weight: 700;
    }
  }
}