.overall-tab-wrapper{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px 10px;
  .dashboard-stat-cards-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    .big-cards-section{
      display: flex;
      gap: 10px;
      .stat-card{
        width: 33%;
      }
    }
    .small-cards-section{
      display: flex;
      gap: 10px;
      .stat-card{
        width: 25%;
      }
    }
  }
  .circle-chart-component{
    width: 100%;
  }
  .vertical-charts{
    display: flex;
    margin-top: 30px;
    gap: 20px;
    justify-content: center;
    width: 100%;
  }
  .time-bar-chart{
    width: 100%;
  }
  .pg-main-wrapper{
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
  }
  .device-lang-stats{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    gap: 20px;
    width: 100%;
  }
  .map-chart-wrapper{
    width: 100%;
    margin-top: 30px;
    border-radius: 20px;
    background: var(--Main-palette-White, #FAFAFA);
    padding: 30px;
    box-sizing: border-box;
    .map-title{
      color: var(--Main-palette-Dino_Dark, #2A2B3B);
      font-family: MacPawFixelSemiBold, 'sans-serif';
      font-size: 24px;
      line-height: 110%; /* 26.4px */
      text-align: left;
    }
    .data{
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}