.tab-dashboard{
  .tabs{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-10, #EAEAEC);
    .tab {
      width: 200px;
      cursor: pointer;
      padding: 5px 10px 15px 10px;
      &:hover {
        background-color: #f0f0f0;
      }
      &.active {
        border-bottom: 2px solid #2A2B3B;
      }
    }
  }
  .tab-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }
}