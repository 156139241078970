.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  label{
    align-self: flex-start;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .custom-copy-input{
    border-radius: 10px;
    border: 1px solid var(--grey-10, #EAEAEC);
    background: var(--main-palette-white, #FAFAFA);
    display: flex;
    height: 48px;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    box-sizing: border-box;
    outline: none;
  }
  img{
    position: absolute;
    right: 20px;
    top: 54%;
    cursor: pointer;
  }
}
