.segment-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .modal-window {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(20px);
    z-index: 2;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .modal-content {
      background-color: #ffffff;
      width: 60.5vw;
      height: 76vh;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      .inner-container {
        padding: 20px 20px 0;
        display: flex;
        flex-direction: column;
        hr {
          width: 100%;
        }
        .modal-manually-title {
          display: flex;
          justify-content: space-between;
          h1 {
            margin: 0 0 0px;
            align-self: center;
            max-width: 258px;
            font-size: 20px;
            font-family: MacpawFixelBold;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
        p {
          align-items: flex-start;
          font-size: 14px;
          align-self: flex-start;
          span {
            font-family: MacpawFixelMedium;
            color: #7e9d00;
          }
        }
      }
      .table {
        width: 100%;
        height: 73%;
        .title-wrapper {
          display: flex;

          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
          .table-title {
            align-self: center;
            font-size: 14px;
            margin-left: 42px;
            display: flex;
            width: 600px;
            justify-content: space-between;
            span {
              color: #2a2b3b;
              font-family: MacpawFixelMedium;
              opacity: 0.5;
            }
          }
        }
      }
      .btns-group {
        width: 100%;
        height: 65px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.06);
        button {
          padding: 10px 20px;
          width: 146px;
          font-size: 14px;
          font-family: MacpawFixelMedium;
          border: none;
          border-radius: 10px;
          cursor: pointer;
          align-self: center;
          margin-right: 10px;
        }
        .btn1 {
          margin-right: 10px;
          background: rgba(42, 43, 59, 0.05);
        }
        .btn2 {
          background: #7e9d00;
          span {
            color: #ffffff;
          }
        }
      }
    }
  }
  .modal-hide {
    display: none;
  }
  .header {
    width: 100%;
    left: 0;
    top: 0;
    border-bottom: 1px solid #e8e8e8;
    height: 80px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    .content-box {
      align-self: center;
      max-width: 1755px;
      margin: 0px 0px 0px 6vw;
      width: 70vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .route-status {
        display: flex;
        align-items: center;
        span {
          p {
            margin: 0;
            color: #7e9d00;
          }
          display: flex;
          font-size: 16px;
          font-family: MacpawFixelMedium;
          margin: 2% 0px 0px 10px;
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
      button {
        width: 160px;
        height: 45px;
        background-color: #7e9d00;
        border-radius: 10px;
        border: none;
        display: flex;
        padding: 10px 20px;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 14px;
          color: #ffffff;
          font-family: MacpawFixelMedium;
          margin-top: 1%;
        }
        img {
          width: 14px;
          height: 6px;
        }
      }
    }
  }
  .pg-bars {
    top: 82px;
    position: absolute;
    display: flex;
    width: 100%;
    align-items: baseline;
    .progress-bar-upload {
      font-family: MacpawFixelBold;
      text-align: left;
      width: 90%;
      margin: 0px 0px 0px 14vw;
      display: flex;
      flex-direction: column;
      h1 {
        margin: 0;
        font-size: 24px;
      }
      .progress-wrapper {
        .parent-prog-bar {
          height: 2px !important;
        }
        .child-prog-bar {
          height: 2px !important;
        }
      }
    }
  }
  .inner-box {
    margin: 12vh 0 0 18vw;
    width: 55vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 1030px;
    .segm-title-manual {
      display: flex;
      align-self: flex-start;
      margin: 0;
      display: flex;
      font-family: MacpawFixelBold;
      h1 {
        margin: 0;
        font-size: 32px;
      }
      .count {
        margin-right: 10px;
        font-size: 32px;
        color: rgba(42, 43, 59, 0.3);
      }
    }
    .upload-group {
      display: flex;
      align-self: flex-end;
      .back {
        width: 120px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 44px;
        background-color: transparent;
        border: 1px solid #7e9d00;
        border-radius: 10px;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
        }
        span {
          margin: 2% 10px 0px 0px;
          font-family: MacPawFixelMedium;
          color: #7e9d00;
          font-size: 14px;
        }
      }
      .save-as-draft {
        width: 156px;
        height: 44px;
        background-color: transparent;
        border: 1px solid #7e9d00;
        border-radius: 10px;
        cursor: pointer;
        margin: 0 0 0 10px;
        span {
          font-family: MacPawFixelMedium;
          color: #7e9d00;
          font-size: 14px;
        }
      }
      .continue {
        cursor: pointer;
        width: 156px;
        height: 44px;
        background-color: #7e9d00;
        border: none;
        border-radius: 10px;
        margin-left: 10px;
        span {
          font-family: MacPawFixelMedium;
          color: #ffffff;
          font-size: 14px;
        }
      }
      :disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
    .pg-bars {
      width: 100%;
      min-width: 1030px;
      display: flex;
      align-items: baseline;
      margin: 20px 0;
      .progress-bar-upload {
        font-family: MacpawFixelBold;
        text-align: left;
        width: 50%;
        margin: 0px 10px 0px 0px;
        display: flex;
        flex-direction: column;
        height: 47px;
        h1 {
          margin: 0 0 4px;
          font-size: 16px;
          opacity: 0.5;
        }
        .progress-wrapper {
          .parent-prog-bar {
            height: 6px !important;
          }
          .child-prog-bar {
            height: 6px !important;
          }
        }
      }
      .progress-bar-segmentation {
        font-family: MacpawFixelBold;
        text-align: left;
        display: flex;
        flex-direction: column;
        height: 47px;
        justify-content: center;
        width: 50%;
        margin: 0px 10px 0px 0px;
        h1 {
          margin: 0;
          font-size: 24px;
        }
        .progress-wrapper {
          .parent-prog-bar {
            height: 6px !important;
          }
          .child-prog-bar {
            height: 6px !important;
          }
        }
      }
    }
    .segm-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tips {
        text-align: left;
        font-size: 14px;
        align-items: flex-start;
        max-width: 440px;
        ul {
          padding: 0 0 0 5%;
          li {
            line-height: 19.32px;
            opacity: 0.7;
          }
        }
      }
      .upload-group {
        align-self: center;
        button {
          cursor: pointer;
        }
        :disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }
    .modal {
      position: absolute;
      top: 0;
      width: 100vw;
      z-index: 99;
      height: 100vh;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(20px);
      .modal-window {
        width: 50vw;
        position: relative;
        padding: 30px 20px;
        height: 57vh;
        border-radius: 20px;
        background-color: #ffffff;
        text-align: left;
        .modal-title {
          display: flex;
          justify-content: space-between;
          h1 {
            margin: 0;
            font-family: MacpawFixelBold;
            font-size: 24px;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
        .data-table {
          font-family: MacpawFixelMedium;
          .table-title {
            display: flex;
            justify-content: space-between;
            color: #2a2b3b;
            font-size: 12px;
            opacity: 0.5;
            .fname {
              margin-left: 15%;
            }
            .lname {
              margin-right: 10%;
            }
          }
          hr {
            color: rgba(42, 43, 59, 0.1);
          }
          .table-content {
            margin-top: 10px;
            .contact {
              display: flex;
              justify-content: space-between;
              font-family: MacpawFixelMedium;
              font-size: 12px;
            }
          }
          .btns-group {
            bottom: 5%;
            right: 5%;
            position: absolute;
            button {
              padding: 10px 20px;
              width: 146px;
              font-size: 14px;
              font-family: MacpawFixelMedium;
              border: none;
              border-radius: 10px;
              cursor: pointer;
            }
            .btn1 {
              margin-right: 10px;
              background: rgba(42, 43, 59, 0.05);
            }
            .btn2 {
              background: #7e9d00;
              span {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .modal-hide {
      display: none;
    }
    .selection {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      align-items: flex-start;
      .dropdown {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        label {
          font-family: MacpawFixelMedium;
          margin: 0px 0px 10px 20px;
          align-self: flex-start !important;
        }
      }
      .tags {
        display: flex;
        flex-direction: column;
        max-width: 440px;
        .MuiStack-root {
          .MuiFormControl-root {
            .MuiInputBase-root {
              padding: 20px;
              background-color: #ffffff;
              input {
                padding: 0;
              }
              .MuiAutocomplete-endAdornment {
                display: none;
              }
              .MuiButtonBase-root {
                background-color: #2a2b3b;
                span {
                  color: #ffffff;
                }
                svg {
                  color: #ffffff;
                }
              }
            }
            ::after {
              border-bottom: none;
            }
          }
        }
        label {
          text-align: left;
          font-size: 16px;
          line-height: 22px;
          margin: 0px 0 10px 20px;
          font-family: MacpawFixelMedium;
        }
        p {
          margin: 10px 0 10px 20px;
          font-family: MacpawFixelMedium;
          max-width: 440px;
          font-size: 14px;
          line-height: 22px;
          text-align: left;
          span {
            cursor: pointer;
            color: #7e9d00;
          }
        }
      }
    }
  }
}

/*
    .container {
                    display: flex;
                    overflow-x: scroll;
                    width: 100%;
                    max-width: 100%;
                    padding: 15px 20px;
                    border: none;
                    border-radius: 20px;
                    align-items: center;
                    background-color: #fff;
                    input{
                        padding: 5px 0!important;
                        width: 100%;
                        min-width: 50%;
                        right: 0;
                        align-self: flex-start!important;
                        font-family: MacpawFixelMedium;
                    }
                    .container-input {
                        border: none;
                        border-radius: none;
                        padding: 0;
                        outline: none;
                        font-size: 14px;
                    }
                    .tag {
                        display: flex;
                        align-items: center;
                        margin: 0px 0;
                        margin-right: 5px;
                        padding: 5px 10px;    
                        max-width: 100%;
                        border-radius: 10px;
                        background-color: #2A2B3B;
                        white-space: nowrap;
                        color: white;
                        img{
                            display: flex;
                            border: none;
                            background-color: unset;
                            cursor: pointer;
                            color: white;
                            width: 16px;
                            height: 16px;
                        }
                        span{
                            line-height: 19.2px;
                            margin-bottom: 3%;
                        }
                    }
                }*/
