:root {
  --main-opacity: 0.9;
  // interface colors
  --dino-white: #FAFAFA;
  --dino-dark: #2A2B3B; // default text color
  --dino-purple: #666DA5;
  --dino-green: #7E9D00;
  --dino-green-bg-default: #7E9D00;
  --dino-green-bg-hover: #98B133D5;
  --dino-green-bg-pressed: #A5BB4D;
  --dino-color-default: #C0C0C5;
  --dino-yellow: #EDB833;
  --dino-orange: #D36700;
  --dino-text-color: #95959D;
  --dino-grey-50: #95959D;
  --dino-grey-40: #AAAAB1;
  --dino-grey-10: #EAEAEC;
  --dino-bg-default: #2A2B3B;
  
  // borders
  --border-color: #E8E8E8;
  --border-color-hover: #2A2B3B;
  --border-color-btn-hover: #98B133D5;
  
  //  tabs
  --tab-color-pressed: #2A2B3B; // pressed text color
  --tab-color-default: #6A6B76; // default text color

  // Text Editor
  --te-icon-bg-pressed: #EAEAEC; // pressed background
  --te-icon-bg-hover: #D4D5D8; // hover background
  
  // colors icon and button
  --btn-icon-bg-default: #EAEAEC; // default background
  --btn-icon-bg-hover: #D4D5D8; // hover background
  --btn-icon-bg-pressed: #F4F4F5; // pressed background
  --btn-icon-color-default: #2A2B3B; // default icon color
  
  // slider
  --slider-bg-default: #EAEAEC; // default background
}

.App {
  position: relative;
  top: 0;
  z-index: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
