.domain-auth{
  align-self: start;
  position: relative;
  width: 100%;
  .header{
    width: 100%;
    left: 0;
    top: 0;
    border-bottom: 1px solid #E8E8E8;
    height: 80px;
    padding: 20px 30px 20px 19.5vw;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    .content-box {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .route-status{
        display: flex;
        align-items: center;
        .settings-span{
          cursor: pointer;
        }
        span, a{
          font-size: 16px;
          font-family: MacpawFixelMedium;
        }
        span{
          margin-right: 10px;
        }
        a{
          color: #7E9D00;
        }
        img{
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
      }
      button{
        width: 160px;
        height: 45px;
        background-color: #7E9D00;
        border-radius: 10px;
        border: none;
        display: flex;
        padding: 10px 20px;
        align-items: center;
        justify-content: center;
        div{
          font-size: 14px;
          color: #ffffff;
          font-family: MacpawFixelMedium;
        }
      }
    }
  }
  .steps-body {
    margin-top: 70px;
    margin-left: 17.1vw;
    display: flex;
    height: auto;
    padding: 40px 30px;
    box-sizing: border-box;
    .step-progress{
      width: 100%;
      display: flex;
      .steps-box{
        width: 330px;
        .step {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 70px;
          .status-icons {
            height: 100%;
            position: relative;
            margin-top: 45px;
            width: 30px;
            .line{
              position: absolute;
              width: 2px;
              height: 50%;
              left: 47%;
              background: #AAAAB1;
            }
          }
          &.current {
            .status-icons{
              .line{
                background: #7e9d00;
              }
            }
          }
        }
      }
      .forms{
        margin-left: 30px;
        max-width: 675px;
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 20px;
        background: #FFF;
      }
    }
  }
}