.tags-wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;

  .header {
    width: 100%;
    left: 0;
    top: 0;
    border-bottom: 1px solid #e8e8e8;
    height: 80px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;

    .content-box {
      align-self: center;
      max-width: 1755px;
      margin: 0px 0px 0px 60px;
      width: 70vw;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .route-status {
        display: flex;
        align-items: center;

        .manual-routes {
          display: flex;
          align-items: baseline;

          span {
            display: flex;
            cursor: pointer;
            font-size: 16px;
            font-family: MacpawFixelMedium;
            margin: 2% 0px 0px 10px;
          }

          p {
            margin: 0 10px;
            color: #7e9d00;
          }
        }

        img {
          width: 18px;
          height: 18px;
        }
      }

      .btns {
        display: flex;
        margin-left: 30px;

        .transparent {
          width: 156px;
          height: 44px;
          background-color: transparent;
          border: 1px solid #7e9d00;
          border-radius: 10px;
          margin-left: 10px;
          cursor: pointer;

          span {
            font-family: MacPawFixelMedium;
            color: #7e9d00;
            font-size: 14px;
          }
        }

        .back {
          display: flex;
          width: 156px;
          height: 44px;
          background-color: transparent;
          border: 1px solid #7e9d00;
          border-radius: 10px;
          margin-left: 10px;
          cursor: pointer;
          align-items: center;
          justify-content: space-evenly;

          span {
            font-family: MacPawFixelMedium;
            color: #7e9d00;
            font-size: 14px;
          }

          img {
            width: 24px;
            height: 24px;
          }
        }

        .filled {
          cursor: pointer;
          width: 156px;
          height: 44px;
          background-color: #7e9d00;
          border: none;
          border-radius: 10px;
          margin-left: 10px;

          span {
            font-family: MacPawFixelMedium;
            color: #ffffff;
            font-size: 14px;
          }
        }

        :disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
    }
  }

  .modal-tag {
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 99;
    height: 100vh;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(20px);

    .modal-window-tag {
      width: 30vw;
      position: relative;
      padding: 30px 20px;
      height: 19vh;
      border-radius: 20px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;

      .modal-tag-title {
        font-family: MacpawFixelMedium;
        font-size: 20px;
        margin: 0;
      }

      span {
        align-self: flex-start;
        margin: 10px 0;
      }

      .input-box {
        position: relative;

        img {
          width: 24px;
          height: 24px;
          position: absolute;
          left: 3%;
          top: 25%;
        }

        .create-input {
          width: 90%;
          font-family: MacpawFixelMedium;
          border: none;
          padding-left: 42px;
          height: 54px;
          font-size: 16px;
          background: rgba(42, 43, 59, 0.05);
          border-radius: 17px;
          outline: none;
        }

        ::placeholder {
          color: #2a2b3b;
          opacity: 0.5;
        }
      }

      .btns-tag {
        margin-top: 20px;
        display: flex;
        justify-content: space-evenly;

        button {
          padding: 10px 20px;
          font-size: 14px;
          font-family: MacpawFixelMedium;
          border-radius: 10px;
          cursor: pointer;
        }

        .btn1 {
          width: 48%;
          border: 1.5px solid #7e9d00;
          background-color: transparent;

          span {
            color: #7e9d00;
          }
        }

        .btn2 {
          width: 48%;
          border: none;
          background: #7e9d00;

          span {
            color: #ffffff;
          }
        }
      }
    }
  }

  .modal-hide {
    display: none;
  }

  .tags-content {
    margin: 90px 0 0 14vw;

    .navigation {
      display: flex;
      padding: 0 60px;
      flex-direction: column;
      border-bottom: 1px solid #e8e8e8;

      h1 {
        align-self: flex-start;
      }

      .filter-component {
        display: flex;
        flex-direction: column;

        .description {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            width: 400px;
            text-align: left;
            font-size: 14px;
          }

          .search-input {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0 11.9vw 0 0px;

            input {
              align-items: center;
              display: flex;
              justify-content: center;
              padding: 10px 20px 10px 50px;
              height: 16px;
              width: 280px;
              background: rgba(42, 43, 59, 0.05);
              border-radius: 10px;
              border: none;
            }

            ::placeholder {
              display: flex;
              margin: 10px 0 0 0;
            }

            img {
              left: 5%;
              top: 25%;
              position: absolute;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
