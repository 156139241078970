.dashboard-wrapper {
  width: 100%;
  height: 100%;
  padding: 10vh 2.5vw 0 18vw;
  box-sizing: border-box;
  .statistic{
    margin: 0;
    font-family: MacPawFixelBold;
  }
  .tab-box{
    margin-top: 45px;
    .overall-tab-wrapper{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px 10px;
    }
  }
  .circle-chart{
    width: 180px;
    height: 180px;
  }
  .dashboard-content{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;
    .compare-btn{
      cursor: pointer;
      border-radius: 10px;
      border: 1.5px solid #BFCE80;
      height: 44px;
      padding: 10px 20px;
      box-sizing: border-box;
      width: 212px;
      span{
        color: var(--Main-palette-Dino_Dark, #2A2B3B);
        font-family: MacPawFixelMedium, 'sans-serif';
        font-size: 14px;
        font-weight: 500;
      }
    }
    .custom-dropdown{
      width: 220px;
      .selected-option{
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        justify-content: space-between;
        display: flex;
        height: 44px;
        background: transparent;
        border-radius: 10px;
        border: 1px solid var(--Main-palette-Green, #7E9D00);
        .main-content{
          display: flex;
          .placeholder{
            margin-left: 10px;
            font-family: MacPawFixelMedium, 'sans-serif';
            span{
              color: var(--Main-palette-Green, #7E9D00);
              font-size: 10px;
              font-weight: 500;
            }
            div{
              color: var(--Main-palette-Dino_Dark, #2A2B3B);
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
        .selected{
          font-family: MacPawFixelMedium, 'sans-serif';
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          span{
            color: var(--Main-palette-Green, #7E9D00);
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
          }
          p{
            margin: 0;
            color: var(--Main-palette-Dino_Dark, #2A2B3B);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
          }
        }
        .dropdown-arrow{
          width: 16px;
          height: 16px;
        }
        span{
          display: flex;
          .placeholder{
            display: flex;
            div{
              margin-left: 10px;
              img{
                width: 16px;
                height: 16px;
              }
              span{
                color: var(--Main-palette-Green, #7E9D00);
                font-family: MacPawFixelMedium;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
              p{
                margin: 0;
              }
            }
          }
        }
      }
      .options{
        position: absolute;
        width: 100%;
        max-width: 220px;
        padding: 20px 10px;
        box-sizing: border-box;
        border-radius: 10px;
        background: #FFF;
        margin-top: 5px;
        border: 0.5px solid #95959D;
        gap: 20px 0;
        div{
          display: flex;
          padding: 10px 15px;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          cursor: pointer;
        }:hover{
                   border-radius: 10px;
                   background: var(--Grey-5, #F4F4F5);
                 }
      }
    }
  }
}
