.sidebar-wrapper{
    width: 12.7vw;
    z-index: 22;
    max-height: 1600px;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    border-right: 1px solid #E8E8E8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    .lower-tabs{
        margin-bottom: 40px;
        position: relative;
        .user-tab{
            z-index: 222;
            display: flex;
            align-items: center;
            .user-name{
                color: var(--Grey-70, #6A6B76);
                font-family: MacPawFixelBold, "sans-serif";
                font-size: 12px;
                margin-left: 10px;
            }
        }
        .upgrade-plan{
            align-self: center;
            display: flex;
            border-radius: 10px;
            border: 1px solid var(--Grey-10, #EAEAEC);
            padding: 20px 25px 20px 10px;
            box-sizing: border-box;
            align-items: center;
            gap: 10px;
            margin-bottom: 40px;
            .sidebar-up-plane{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-align: left;
                .up-plan-span{
                    color: var(--Main-palette-Green, #7E9D00);
                    font-size: 14px;
                    font-family: MacPawFixelBold, "sans-serif";
                }
                .up-plan-text{
                    margin: 5px 0 0;
                    color: var(--Grey-50, #95959D);
                    font-size: 12px;
                    font-family: MacPawFixelMedium, 'sans-serif';
                }
            }
        }
    }
    .upper-box{
        z-index: 2;
        margin-top: 20px;
        width: 175px;
        align-self: center;
        .balance{
            display: flex;
            border-radius: 10px;
            border: 1px solid var(--Grey-10, #EAEAEC);
            padding: 15px;
            box-sizing: border-box;
            align-items: center;
            gap: 10px;
            .count-balance{
                color: var(--Main-palette-Green, #7E9D00);
                font-family: MacPawFixelBold, "sans-serif";
                font-size: 16px;
                font-weight: 700;
                display: flex;
                align-items: center;
                span{
                    margin-left: 5px;
                }
            }
        }
        .side-logo{
            display: flex;
            align-items: center;
            margin: 0px 0px 50px;
            img{
                width: 120px;
                height: 34px;
            }
            span{
                margin-left: 5px;
                color: #7E9D00;
                font-family: GilroyBold;
            }
        }
        a{
            text-decoration: none;
            justify-content: flex-start;
            display: flex;
            background-color: transparent;
            border: none;
            align-items: center;
            max-width: 175px;
            height: 26px;
            padding: 15px;
            margin: 20px 0;
            color: #2A2B3B;
            cursor: pointer;
            img{
                width: 16px;
                height: 16px;
            }
            span{
                font-family: MacPawFixelRegular;
                font-size: 16px;
                margin-left: 10px;
            }
        }:focus{
            background-color: #7E9D00;
            border-radius: 10px;
            span{
                color: #ffffff;
            }
            
        }
    }
    .button-wrapper{
        .line{
            height: 1px;
            width: 100%;
            background: #EAEAEC;
        }
        .logout{
            align-self: center;
            width: 175px;
            padding: 30px 15px 15px 15px;
            box-sizing: border-box;
            background: transparent;
            margin-bottom: 30px;
            display: flex;
            border: none;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            img{
                width: 14px;
                height: 14px;
            }
            div{
                font-family: MacPawFixelLight;
                font-size: 16px;
                margin-left: 10px;
                color: #FF5656;
            }
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .modal{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        right: 0;
        background: rgba(0, 0, 0, 0.80);
        backdrop-filter: blur(20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 1;
        animation: fadeIn .25s ease-in-out;
        .modal-window{
            width: 360px;
            height: 130px;
            border-radius: 20px;
            background: #FFF;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            h1{
                color: var(--main-palette-dino-dark, #2A2B3B);
                text-align: center;
                font-family: MacPawFixelBold;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
                margin: 0;
            }
            .button-modal-box{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: MacPawFixelMedium;
                button{
                    width: 152px;
                    height: 44px;
                    border-radius: 10px;
                    padding: 10px 20px;
                    box-sizing: border-box;
                    border: none;
                    cursor: pointer;
                }
                .cancel{

                }
                .logout{
                    align-items: center;
                    display: flex;
                    background: #FF5656;
                    div{
                        font-size: 14px;
                        color: #FAFAFA;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    //.user-box{
    //    width: 175px;
    //    display: flex;
    //    flex-direction: column;
    //    justify-content: space-between;
    //    padding: 10px 10px 10px 10px;
    //    align-self: center;
    //    align-items: center;
    //    box-sizing: border-box;
    //    .popup{
    //        width: 100%;
    //        border-bottom: 1px solid #E8E8E8;
    //        padding: 0px 0 10px;
    //        .popup-box, .logout{
    //            display: flex;
    //            justify-content: flex-start;
    //            height: 44px;
    //            align-items: center;
    //            cursor: pointer;
    //            img{
    //                width: 24px;
    //                height: 24px;
    //            }
    //            span{
    //                font-family: MacPawFixelMedium;
    //                font-size: 14px;
    //                line-height: 130%;
    //                margin-left: 10px;
    //            }
    //        }
    //        .popup-box:hover, .logout:hover{
    //            span{
    //                text-shadow: 2px 5px 4px rgba(0, 0, 0, 0.25);
    //            }
    //            img{
    //                box-shadow: 0px 3px 2px  rgba(0, 0, 0, 0.25);
    //            }
    //        }
    //        .logout-hide{
    //            display: none;
    //        }
    //    }
    //    .user-container{
    //        display: flex;
    //        align-items: center;
    //        width: 100%;
    //        justify-content: space-between;
    //        align-self: flex-end;
    //        padding-top: 10px;
    //        .user-data{
    //            display: flex;
    //            align-items: center;
    //            img{
    //                width: 36px;
    //                height: 36px;
    //            }
    //            span{
    //                font-family: MacPawFixelMedium;
    //                margin-left: 5px;
    //            }
    //        }
    //        .options{
    //            width: 20%;
    //            .dots{
    //                width: 4px;
    //                height: 18px;
    //                cursor: pointer;
    //            }
    //        }
    //    }
    //}
}
