.dino-input-icon-block {
  .dino-label-styles {
    display: block;
    margin: 10px 0;
    color: var(--dino-dark);
    caret-color: transparent;
  }
  
  .dino-input-icon-wrapper {
    position: relative;
    
    .dino-search-icon, .dino-close-icon {
      position: absolute;
      top: 14px;
      cursor: pointer;
      caret-color: transparent;
    }
    
    .position-left {
      left: 8px;
    }
    
    .position-right {
      right: 10px;
    }
    
    .dino-input-styles {
      width: 100%;
      height: 40px;
      padding: 0 10px;
      margin: 0;
      box-sizing: border-box;
      border-radius: 10px;
      border: none;
      background: var(--btn-icon-bg-pressed);
      outline-color: var(--dino-green-bg-default);
      
      &.is-active-icon {
        padding: 0 25px;
      }
    }
  }
}

.input-box {
  max-width: 560px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  
  & label {
    font-family: MacPawFixelMedium;
    color: #2A2B3B;
    font-size: 14px;
    margin: 5px 0 5px 20px;
  }
  
  & input {
    width: 100%;
    padding: 10px 20px;
    height: 44px;
    box-sizing: border-box;
    font-size: 14px;
    border: 1.5px solid #D4D5D8;
    border-radius: 10px;
    font-family: MacPawFixelMedium;
    line-height: 25px;
    outline: #7E9D00;
  }
  
  &:focus {
    border: 1px solid #7E9D00;
  }
  
  & input::placeholder {
    font-family: MacPawFixelRegular;
    font-size: 14px;
    opacity: 0.3;
  }
}
