.form-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .step-description{
    text-align: left;
  }
  .inputs-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input-box{
      width: 250px;
      label{
        color: #95959D;
      }
      input::placeholder{
        font-family: MacPawFixelRegular;
        font-size: 14px;
        opacity: 1;
      }
    }
  }
  .custom-dropdown {
    position: relative;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .dropdown-label{
      font-family: MacPawFixelMedium;
      font-size: 14px;
      margin: 5px 0 5px 20px;
      color: #95959D;
    }
    .selected-option {
      width: 100%;
      height: 44px;
      padding: 10px 20px;
      border: 1.5px solid #D4D5D8;
      cursor: pointer;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: 10px;
      justify-content: space-between;
      img{
        width: 24px;
        height: 24px;
      }
      span{
        font-size: 14px;
        color: #95959D;
      }
    }
    .options {
      height: 300px;
      overflow-y: auto;
      list-style: none;
      padding: 0;
      margin: 7px 0 0;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 1.5px solid #D4D5D8;
      border-radius: 10px;
      background: #FFF;
      display: flex;
      flex-direction: column;
      li {
        align-items: flex-start;
        text-align: left;
        padding: 10px 20px;
        cursor: pointer;
        color: #95959D;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
}
.second-form-content , .third-form-content, .fourth-form-content{
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  font-family: MacPawFixelMedium;
  justify-content: space-between;
  p{
    font-size: 16px;
    margin: 0;
  }
  ul{
    padding: 0 10px;
    li{
      font-size: 14px;
      opacity: 0.7;
      line-height: 250%;
    }
  }
  a{
    font-size: 14px;
    color: #7E9D00;
    cursor: pointer;
  }
  button{
    margin-top: 40px;
  }
}.fourth-form-content{
  .title-form{
    color: var(--main-palette-green, #7E9D00);
    font-family: MacPawFixelBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .form-text{
    margin-top: 30px;
    color: var(--grey-70, #6A6B76);
    font-family: MacPawFixelMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
 }
 .pending-status, .success-status{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   p{
     color: var(--main-palette-green, #7E9D00);
     font-family: MacPawFixelBold;
     font-size: 24px;
     font-style: normal;
     font-weight: 700;
     line-height: normal;
     margin: 0;
   }
   span{
     font-family: MacPawFixelMedium;
     font-size: 16px;
     margin-top: 10px;
   }
   button{
     margin-top: 30px;
   }
 }
 .pending-status{
   button{
     border: 1px solid #95959D;
     opacity: 0.7;
   }
 }
.third-form-content{
  .cnames{
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .horizontal-line{
      width: 1px;
      height: 100%;
      background: #D4D5D8;
      margin: 0 20px;
    }
    .cname_1, .cname_2{
      width: inherit;
      p{
        margin-bottom: 25px;
        font-family: MacPawFixelBold;
        font-size: 16px;
      }
      .input-wrapper{
        &:nth-child(2) {
          margin-bottom: 20px;
        }
        input::placeholder{
          opacity: 0.3;
        }
      }
    }
  }
}
