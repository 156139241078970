.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  margin: 1em auto;
}
.highcharts-container{
  width: 100%;
}
.highcharts-halo.highcharts-color-undefined {
  fill-opacity: 1 !important;
}

.highcharts-credits{
  display: none!important;
}
.highcharts-axis{
  display: none;
}
.highcharts-plot-background{
  margin-top: 10px;
}
#container {
  padding: 20px;
  box-sizing: border-box;
  height: 400px;
  font-family: MacPawFixelBold, 'sans-serif';
}

text{
  font-family: MacPawFixelBold, 'sans-serif';
}

.highcharts-label.highcharts-tooltip{
  display: none;
}
.highcharts-point.highcharts-color-0{
  border-radius: 20px;
  fill: #9FCAFB;
  cursor: pointer;
}
.highcharts-point.highcharts-color-0:hover{
  fill: #5B93A9;
 }

.highcharts-axis-title{
  display: none;
}

.highcharts-legend-item{
  display: none;
}

.highcharts-data-table table {
  font-family: MacPawFixelMedium, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-background{
  padding: 20px;
  box-sizing: border-box;
}
.highcharts-root{

}
.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}
