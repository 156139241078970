.MuiPaper-root {
  overflow-x: hidden;
  box-shadow: none;
  margin-left: 0.5%;
}
.MuiTooltip-popper {
  display: none !important;
}
.custom-header-checkbox {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  top: 12%;
  left: 13%;
  height: 16px;
  background-color: white;
  border: 2px solid #7e9d00;
  border-radius: 5px;
  display: inline-block;
  transition: background-color 0.2s, border-color 0.2s;
  &:checked {
    background-color: #7e9d00; // Change to your desired background color
    border: 2px solid #7e9d00; // Change to your desired border color
  }
  // Custom checkbox indicator (default checkmark)
  &::after {
    content: '\-'; // Checkmark symbol
    font-size: 22px;
    position: absolute;
    top: 36%;
    left: 24.5%;
    transform: translate(-50%, -50%);
    color: white;
    display: none;
  }

  &:checked::after {
    display: block;
  }
}
.custom-checkbox {
  // Hide the default checkbox appearance
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 2px solid #7e9d00;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  transition: background-color 0.2s, border-color 0.2s;
  &:checked {
    background-color: #7e9d00; // Change to your desired background color
    border: 2px solid #7e9d00; // Change to your desired border color
  }
  // Custom checkbox indicator (default checkmark)
  &::after {
    content: '';
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 10%;
    left: 30%;
    display: none;
  }

  &:checked::after {
    display: block;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation4.tss-11quiee-MUIDataTable-paper.tss-1x5mjc5-MUIDataTable-root.container.css-11mde6h-MuiPaper-root {
  /*parent table block*/
  box-shadow: none;
  .tss-1cdcmys-MUIDataTable-responsiveBase {
    .tss-8cvlcf-MUIDataTableResize-root {
      div {
        div {
          margin-top: 7px;
          height: 4%;
          border: none;
          border-left: 0.1px solid rgba(224, 224, 224, 1);
          border-right: 0.1px solid rgba(224, 224, 224, 1);
        }
      }
    }
    button {
      div {
        div {
          height: 10px;
        }
      }
    }
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableRow-root {
          position: relative;
          .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.tss-10syd3x-MUIDataTableHeadCell-root.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader.css-1ygcj2i-MuiTableCell-root {
            div {
              margin-left: 15px;
              display: inline-block;
              input {
                position: static;
              }
            }
          }
          .MuiTableCell-root {
            padding: 0 0 0 1px;
            span {
              button:hover {
                background: transparent;
              }
            }
            div {
              justify-content: flex-end;
              align-items: flex-end;
              span {
                svg {
                  font-size: 1rem;
                  color: #7e9d00;
                }
                //input[type=checkbox]:checked {
                //  background-color: #9b59b6 !important;
                //  color: #ffffff !important;
                //}
              }
              span:hover {
                background: transparent;
              }
            }

            .tss-178gktx-MUIDataTableHeadCell-contentWrapper {
              /*span*/
              button {
                div {
                  align-items: center;
                  div {
                    height: 18px;
                    text-transform: capitalize;
                    font-family: MacPawFixelMedium;
                    opacity: 0.3;
                  }
                }
              }
            }
          }
          .MuiTableCell-root {
            padding: 0 0 0 1px;
            span {
              button {
                div {
                  div {
                    height: 10px;
                  }
                }
              }
            }
          }
        }
      }
      .MuiTableBody-root {
        position: relative;
        tr {
          border-collapse: collapse;
          display: table-row;
          vertical-align: middle;
          td {
            display: table-cell;
            vertical-align: inherit;
          }
        }
        .MuiTableRow-root {
          position: relative;
          cursor: pointer;
          align-items: center;
          .img-box {
            position: absolute;
            margin-top: 0.8%;
            z-index: 222 !important;
            img {
              width: 24px;
              height: 24px;
            }
            .del-img {
              margin-left: 5px;
            }
          }
          .MuiTableCell-root {
            border-bottom: 0;
            div {
              justify-content: flex-end;
              span {
                svg {
                  color: #7e9d00;
                  font-size: 1rem;
                }
              }
            }
            .tss-1qtl85h-MUIDataTableBodyCell-root {
              opacity: 0.7;
            }
          }
        }
        tr:nth-child(odd) {
          background: #f7f7f7;
        }
        .MuiTableRow-root:hover {
          .MuiTableCell-root {
            .tss-1qtl85h-MUIDataTableBodyCell-root {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
