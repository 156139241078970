.building-icon {
  width: 80px;
  height: 80px;
}

.building-blocks-item:hover > .paragraph-icon rect:nth-child(n+1) {
  fill: var(--dino-color-default);
  opacity: var(--main-opacity);
}
.building-blocks-item:hover > .paragraph-icon rect:nth-child(n+2) {
  fill: var(--dino-purple);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .header-icon rect:nth-child(1) {
  fill: var(--dino-green-bg-default);
  opacity: var(--main-opacity);
}
.building-blocks-item:hover > .header-icon rect:nth-child(n+2),
.building-blocks-item:hover > .header-icon circle:nth-child(n+3) {
  fill: var(--dino-color-default);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .list-icon rect:nth-child(1) {
  fill: var(--dino-color-default);
  opacity: var(--main-opacity);
}
.building-blocks-item:hover > .list-icon rect:nth-child(n+2),
.building-blocks-item:hover > .list-icon circle:nth-child(n+2) {
  fill: var(--dino-green-bg-default);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .button-icon rect {
  fill: var(--dino-green-bg-default);
  opacity: var(--main-opacity);
}
.building-blocks-item:hover > .button-icon path {
  fill: var(--dino-dark);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .image-icon path {
  fill: var(--dino-yellow);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .spacing-icon path {
  fill: var(--dino-orange);
  opacity: var(--main-opacity);
}
.building-blocks-item:hover > .spacing-icon rect {
  fill: var(--dino-color-default);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .divider-icon rect:nth-child(1),
.divider-icon:hover rect:nth-child(3) {
  fill: var(--dino-color-default);
  opacity: var(--main-opacity);
}
.building-blocks-item:hover > .divider-icon rect:nth-child(2) {
  fill: var(--dino-orange);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .social-icon path {
  fill: var(--dino-orange);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .html-icon path {
  fill: var(--dino-purple);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .footer-icon path {
  fill: var(--dino-color-default);
  opacity: var(--main-opacity);
}
.building-blocks-item:hover > .footer-icon path:nth-child(1) {
  fill: var(--dino-purple);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .video-icon path:nth-child(n+1),
.building-blocks-item:hover > .video-icon g:nth-child(n+2) {
  fill: var(--dino-orange);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .logo-icon path {
  fill: var(--dino-yellow);
  opacity: var(--main-opacity);
}

.building-blocks-item:hover > .giphy-icon path:nth-child(n+1) {
  fill: var(--dino-purple);
  opacity: var(--main-opacity);
}

.building-text {
  margin: 0;
  color: var(--dino-dark);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Text Editor toolbar*/
.toolbar-btn-wrapper {
  width: 100%;
  padding: 6px 0;
  height: 40px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  
  &:hover {
    border: 1px solid var(--border-color-btn-hover);
    border-radius: 8px;
  }
  
  &:hover .write-icon path {
    fill: var(--dino-green-bg-hover);
  }
  
  &:hover .spelling-icon path {
    stroke: var(--dino-green-bg-hover);
  }
  
  &:hover .continue-icon path {
    stroke: var(--dino-green-bg-hover);
  }
  
  &:hover .shorten-icon path {
    stroke: var(--dino-green-bg-hover);
  }
  
  &:hover .expand-icon path {
    stroke: var(--dino-green-bg-hover);
  }
  
  &:hover .emojis-icon path {
    stroke: var(--dino-green-bg-hover);
  }
  
  &:hover .tone-icon path {
    stroke: var(--dino-green-bg-hover);
  }
  
  .toolbar-btn-text {
    font-size: 10px;
    font-family: MacPawFixelMedium;
    color: var(--dino-text-color);
  }
  
  &:hover .toolbar-btn-text {
    color: var(--dino-green-bg-hover);
    font-weight: 700;
  }
}
