.capmaigns-wrapper {
  width: 100vw;
  height: 100vh;
  align-self: start;
  position: relative;
  .btn-wrapper {
    .create-camp-btn {
      cursor: pointer;
      width: 205px;
      height: 44px;
      box-sizing: border-box;
      padding: 10px 20px;
      background: #7e9d00;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      span{
        color: var(--Main-palette-White, #FAFAFA);
        font-family: MacPawFixelMedium, 'sans-serif';
        font-size: 13px;
        font-weight: 500;
      }
      .camp-arr{
        width: 24px;
        height: 24px;
      }
    }
  }
  //.header {
  //  width: 100%;
  //  left: 0;
  //  top: 0;
  //  border-bottom: 1px solid #e8e8e8;
  //  height: 80px;
  //  display: flex;
  //  position: absolute;
  //  align-items: center;
  //  justify-content: center;
  //  background-color: #ffffff;
  //  .content-box {
  //    align-self: center;
  //    max-width: 1755px;
  //    margin: 0px 0px 0px 130px;
  //    width: 70vw;
  //    display: flex;
  //    align-items: center;
  //    justify-content: space-between;
  //    .route-status {
  //      display: flex;
  //      align-items: center;
  //      .manual-routes {
  //        display: flex;
  //        align-items: baseline;
  //        span {
  //          display: flex;
  //          cursor: pointer;
  //          font-size: 16px;
  //          font-family: MacpawFixelMedium;
  //          margin: 2% 0px 0px 10px;
  //        }
  //        p {
  //          margin: 0 10px;
  //          color: #7e9d00;
  //        }
  //      }
  //      img {
  //        width: 18px;
  //        height: 18px;
  //      }
  //    }
  //    .btns {
  //      display: flex;
  //      margin-left: 30px;
  //      .filled {
  //        cursor: pointer;
  //        width: 205px;
  //        height: 44px;
  //        background-color: #7e9d00;
  //        border: none;
  //        border-radius: 10px;
  //        margin-left: 10px;
  //        align-items: center;
  //        display: flex;
  //        justify-content: space-evenly;
  //        span {
  //          font-family: MacPawFixelMedium;
  //          color: #ffffff;
  //          font-size: 14px;
  //        }
  //        img {
  //          width: 24px;
  //          height: 24px;
  //        }
  //      }
  //      :disabled {
  //        opacity: 0.5;
  //        cursor: default;
  //      }
  //    }
  //  }
  //}
  .hide-upload {
    display: none;
  }
  .placeholder-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    top: 30vh;
    left: 40vw;
    width: 520px;
    height: 420px;
    padding: 20px;
    border-radius: 20px;
    .campaign-desc {
      h3 {
        font-family: MacPawFixelBold;
        font-size: 24px;
        margin: 0px 0px 10px auto;
      }
      p {
        margin: 0 0 auto;
        font-size: 16px;
        line-height: 22px;
        font-family: MacPawFixelMedium;
      }
    }
    .create-btn-holder {
      background-color: #7e9d00;
      height: 45px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      width: 205px;
      span {
        color: var(--Main-palette-White, #FAFAFA);
        font-family: MacPawFixelMedium, 'sans-serif';
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .campaigns-box {
    padding: 10vh 2.5vw 0 18.5vw;
    box-sizing: border-box;
    margin: 0;
    .c-title {
      text-align: left;
      max-width: 40vw;
    }
    .inner-box {
      display: flex;
      gap: 20px;
      .tabs {
        h4 {
          text-align: left;
          margin: 20px 0;
          font-size: 16px;
        }
        .upper-container {
          padding-bottom: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid #cacaca;
        }
        .link,
        .link-s,
        .emails {
          display: flex;
          align-items: center;
          margin: 10px 0;
          opacity: 0.5;
          img {
            width: 24px;
            height: 24px;
          }
          .text {
            margin-left: 10px;
          }
          .status {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 54px;
            background: #e4e4e6;
            border-radius: 10px;
            font-size: 10px;
            margin-left: 20px;
          }
        }
        .link,
        .emails {
          cursor: pointer;
        }
        .link-s {
          cursor: default;
        }
        .emails:hover {
          opacity: 1;
        }
        .link:hover {
          opacity: 1;
        }
      }
      .cards {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .no-results-image{
          margin-top: 150px;
          display: flex;
          margin-left: 17vw;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span{
            color: var(--Main-palette-Dino_Dark, #2A2B3B);
            text-align: center;
            font-family: MacPawFixelBold, "sans-serif";
            font-size: 24px;
            margin: 20px 0 10px;
          }
          p{
            color: var(--Grey-50, #95959D);
            text-align: center;
            font-family: MacPawFixelMedium, 'sans-serif';
            font-size: 14px;
            margin: 0;
          }
        }
        .search-input {
          position: relative;
          display: flex;
          align-items: center;
          margin: 0 11.9vw 30px 0;
          input {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 10px 20px 10px 50px;
            height: 16px;
            width: 280px;
            background: rgba(42, 43, 59, 0.05);
            border-radius: 10px;
            border: none;
          }
          ::placeholder {
            display: flex;
            margin: 10px 0 0 0;
          }
          img {
            left: 4%;
            top: 25%;
            position: absolute;
            width: 16px;
            height: 16px;
          }
        }
        .fade-out {
          transition: opacity 0.5s ease-out; /* Adjust the duration and easing as needed */
          opacity: 0;
        }
        .card {
          box-sizing: border-box;
          border-radius: 20px;
          padding: 20px;
          display: flex;
          cursor: default;
          width: 100%;
          transition: border-color 0.3s;
          border: 1px solid transparent; /* Add a transparent border by default */
          background-color: #ffffff;
          align-items: center;
          position: relative;
          margin-bottom: 10px;
          &.fade-out {
            opacity: 0;
            transition: opacity 0.5s ease-out;
          }
          .button-wrapper {
            right: 20px;
          }
          img {
            align-self: flex-start;
            width: 24px;
            height: 24px;
          }
          .card-content {
            margin-left: 10px;
            text-align: left;
            .card-title {
              align-items: center;
              display: flex;
              h5 {
                font-size: 14px;
                font-family: MacPawFixelmedium;
                margin: 0;
              }
              .tab-select {
                margin-left: 20px;
                padding: 5px 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 10px;
                border-radius: 5px;
                span {
                  font-size: 10px;
                  color: #ffffff;
                  font-family: MacPawFixelMedium;
                }
              }
            }
            .status {
              span {
                font-size: 14px;
              }
              opacity: 0.5;
            }
            .type,
            .des {
              opacity: 0.5;
              font-size: 14px;
            }
          }
        }
        .card:hover {
          border: 1px solid rgba(42, 43, 59, 0.2);
        }
      }
    }
  }
}

//@media (max-width: 1280px) {
//  .capmaigns-wrapper {
//    .header {
//      .content-box {
//        width: 75vw;
//        margin-left: 185px;
//      }
//    }
//    .upload-campaign {
//      height: 120px;
//      .cards {
//        .email,
//        .abTesting,
//        .SMS,
//        .journey,
//        .automations {
//          max-width: 170px;
//          padding: 10px;
//          .cooming-soon {
//            align-items: center;
//            width: 45px;
//            span {
//              font-size: 10px;
//              margin-bottom: 10px;
//            }
//          }
//          .upload-des {
//            width: auto;
//            h4 {
//              font-size: 12px;
//            }
//            p {
//              font-size: 10px;
//            }
//          }
//        }
//      }
//    }
//  }
//}
