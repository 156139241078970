.rti--container {
  padding: 20px;
  border-radius: 20px;
  border: none;
  outline: none;
  input {
    width: 59%;
    min-width: 30%;
    outline: none;
  }
  ::placeholder {
    font-size: 14px;
    font-family: MacPawFixelMedium;
  }
  .rti--tag {
    background-color: #2a2b3b;
    span {
      color: #ffffff;
    }
    button {
      color: #ffffff;
    }
  }
}
