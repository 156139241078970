.table-modal {
  .modal-container {
    position: absolute;
    right: 0;
    top: 5vh;
    z-index: 2;
    .filter-modal {
      width: 325px;
      height: 575px;
      border-radius: 10px;
      border: 1px solid rgba(42, 43, 59, 0.2);
      box-sizing: border-box;
      background: #ffffff;
      overflow-y: auto;
      position: relative;
      .search-box {
        position: relative;
        box-sizing: border-box;
        border: none;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        padding: 0;
        input {
          border-radius: 10px 10px 0px 0px;
          box-sizing: border-box;
          border: none;
          width: 100%;
          height: 56px;
          padding: 0 0 0 50px;
          outline: none;
        }
        input::placeholder {
        }
        img {
          top: 35%;
          width: 16px;
          height: 16px;
          left: 7%;
          position: absolute;
        }
      }
      .checkbox-types {
        overflow-y: auto;
        height: 460px;
        .visible {
          text-align: left;
          h1 {
            font-family: MacPawFixelBold;
            font-size: 16px;
          }
          padding: 0 0 0 20px;
          .inputs {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            label {
              display: flex;
              align-items: center;
              margin-top: 15px;
              .custom-modal-checkbox {
                // Hide the default checkbox appearance
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                width: 16px;
                height: 16px;
                background-color: white;
                border: 2px solid #7e9d00;
                border-radius: 5px;
                display: inline-block;
                position: relative;
                transition: background-color 0.2s, border-color 0.2s;
                &:checked {
                  background-color: #7e9d00; // Change to your desired background color
                  border: 2px solid #7e9d00; // Change to your desired border color
                }
                // Custom checkbox indicator (default checkmark)
                &::after {
                  content: '';
                  width: 3px;
                  height: 6px;
                  border: solid white;
                  border-width: 0 2px 2px 0;
                  transform: rotate(45deg);
                  position: absolute;
                  top: 10%;
                  left: 30%;
                  display: none;
                }

                &:checked::after {
                  display: block;
                }
              }
              input {
                margin: 0;
              }
              span {
                opacity: 1;
                margin-left: 10px;
              }
            }
          }
        }
        .hidden {
          text-align: left;
          padding: 0 0 0 20px;
          h1 {
            font-family: MacPawFixelBold;
            font-size: 16px;
          }
          .inputs {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            label {
              display: flex;
              align-items: center;
              margin-top: 15px;
              .custom-modal-checkbox {
                // Hide the default checkbox appearance
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                width: 16px;
                height: 16px;
                background-color: white;
                border: 2px solid #7e9d00;
                border-radius: 5px;
                display: inline-block;
                position: relative;
                transition: background-color 0.2s, border-color 0.2s;
                &:checked {
                  background-color: #7e9d00; // Change to your desired background color
                  border: 2px solid #7e9d00; // Change to your desired border color
                }
                // Custom checkbox indicator (default checkmark)
                &::after {
                  content: '';
                  width: 3px;
                  height: 6px;
                  border: solid white;
                  border-width: 0 2px 2px 0;
                  transform: rotate(45deg);
                  position: absolute;
                  top: 10%;
                  left: 30%;
                  display: none;
                }

                &:checked::after {
                  display: block;
                }
              }

              input {
                margin: 0;
              }

              span {
                opacity: 1;
                margin-left: 10px;
              }
            }
          }
        }
      }
      .bottom-btns {
        bottom: 0;
        width: 100%;
        z-index: 12;
        height: 52px;
        display: flex;
        align-items: center;
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
        justify-content: space-evenly;
        button {
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 20px;
          cursor: pointer;
          span {
            font-family: MacPawFixelMedium;
            margin: 0;
            opacity: 1;
            font-size: 14px;
          }
        }
        .filled {
          background: #7e9d00;
          border: none;
          border-radius: 10px;
          span {
            color: #ffffff;
          }
        }
        .transparent {
          background: transparent;
          border: 1.5px solid #7e9d00;
          border-radius: 10px;
          span {
            color: #2a2b3b;
          }
        }
        .void {
          border: none;
          background: transparent;
          span {
            text-decoration: underline;
          }
        }
      }
    }
    .filter-modal-hide {
      display: none;
    }
  }
}
