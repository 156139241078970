.modal-wrapper {
  .modal-shadow {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    background-color: black;
    opacity: 0.7;
    z-index: 4;
  }
  
  .modal-container {
    width: 500px;
    background-color: var(--dino-white);
    position: fixed;
    top: 75px;
    left: calc(50% - 250px);
    z-index: 5;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 15px 30px;
    
    @media (max-width: 500px) {
      left: 0;
      margin: 0px 10px;
    }
    
    .modal-header {
      padding: 0;
      caret-color: transparent;
    }
    
    .modal-content {
      padding: 10px 0;
    }
    
    .modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}
.container-x {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.box-x {
  border: 1px solid red;
  min-width: 400px;
}

.dragger-x {
  cursor: pointer;
  float: right;
  margin: 0;
  height: 100%;
  background: #0a66b7;
  
  &.dragger-x::after {
    content: url(../../assets/images/dragIcon.svg);
    cursor: move;
  }
}
