.reg-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 90%;
  .register {
    display: flex;
    flex-direction: column;
    position: relative;
    .logo {
      margin-left: 20px;
      align-self: flex-start;
      img {
        width: 120px;
        height: 34px;
      }
    }
    .mark {
      position: absolute;
      width: 155px;
      height: 155px;
      right: 0;
      top: -5%;
    }
    h1 {
      font-family: MacPawFixelMedium;
      text-align: left;
      margin-left: 20px;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .email {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        span {
          font-family: MacPawFixelMedium;
          color: #2A2B3B;
          font-size: 14px;
          margin: 5px 0 5px 20px;
        }
        input {
          width: 100%;
          padding: 10px 20px;
          height: 44px;
          box-sizing: border-box;
          font-size: 14px;
          border: 1.5px solid #D4D5D8;
          border-radius: 10px;
          font-family: MacPawFixelMedium;
          line-height: 25px;
          outline: #7E9D00;
        }
        :focus {
          border: 1.5px solid #7E9D00;
        }
        input::placeholder {
          font-family: MacPawFixelRegular;
          font-size: 14px;
          opacity: 0.3;
        }
      }
      .pass {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        margin-top: 20px;
        label {
          font-family: MacPawFixelMedium;
          color: #2A2B3B;
          font-size: 14px;
          margin: 5px 0 5px 20px;
        }
        .pass-input {
          width: 100%;
          position: relative;
          input {
            width: 100%;
            padding: 10px 20px;
            height: 44px;
            box-sizing: border-box;
            font-size: 16px;
            border: 1.5px solid #D4D5D8;
            border-radius: 10px;
            font-family: MacPawFixelMedium;
            line-height: 25px;
            outline: none;
          }
          :focus {
            border: 1.5px solid #7E9D00;
          }
          input::placeholder {
            font-family: MacPawFixelRegular;
            font-size: 14px;
            opacity: 0.3;
          }
          img {
            position: absolute;
            right: 2vh;
            top: 22%;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }
        .error-show {
          display: flex;
          color: red;
          font-family: MacPawFixelRegular;
          margin: 0px 0px 0px 20px;
        }
        .error-hide {
          display: none;
        }
        .radio-btn {
          display: flex;
          margin: 15px 0 0 20px;
          font-size: 12px;
          font-family: MacPawFixelRegular;
          line-height: 17px;
          span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            font-size: 12px;
            font-family: MacPawFixelMedium;
            padding: 0;
            margin-top: 3px;
          }
          .custom-checkbox {
            // Hide the default checkbox appearance
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 16px;
            height: 16px;
            background-color: white;
            border: 2px solid #7E9D00;
            border-radius: 5px;
            display: inline-block;
            position: relative;
            transition: background-color 0.2s, border-color 0.2s;
            cursor: pointer;
            &:checked {
              background-color: #7E9D00; // Change to your desired background color
              border: 2px solid #7E9D00; // Change to your desired border color
            }
            // Custom checkbox indicator (default checkmark)
            &::after {
              content: '';
              width: 3px;
              height: 6px;
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              position: absolute;
              top: 1.2%;
              left: 27%;
              display: none;
            }

            &:checked::after {
              display: block;
            }
          }
        }
      }
      .sign-up {
        display: flex;
        margin: 20px 0 20px;
        cursor: pointer;
        width: 100%;
        background: #7E9D00;
        box-sizing: border-box;
        height: 44px;
        border: none;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border-radius: 10px;
        div {
          color: #F2F2F2;
          font-family: MacPawFixelMedium;
        }
        :disabled {
          div {
            opacity: 0.5;
          }
        }
      }
      .sign-up:hover {
        opacity: 0.8;
      }
      .sign-disabled {
        opacity: 0.5;
        display: flex;
        padding: 10px 20px;
        height: 44px;
        box-sizing: border-box;
        margin: 20px 0 20px;
        cursor: pointer;
        width: 100%;
        background: #C7C7C7;
        border: none;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        div {
          color: #F2F2F2;
          font-family: MacPawFixelMedium;
        }
      }
    }
    .privacy {
      display: flex;
      align-items: center;
      margin-top: 10px;
      img {
        align-self: flex-start;
        width: 16px;
        height: 16px;
        margin: 2px;
      }
      p {
        opacity: 0.4;
        margin: 0;
        font-size: 13px;
        line-height: 150%;
        font-family: MacPawFixelMedium;
        a {
          font-size: 14px;
          text-decoration: underline;
        }
      }
    }
    .sign-in {
      width: auto;
      font-size: 16px;
      align-items: center;
      align-self: center!important;
      display: flex;
      font-family: MacPawFixelMedium;
      margin-top: 150px;
      p {
        font-size: 16px;
        margin: 0 0 0 5px;
        text-decoration: underline;
        cursor: pointer;
        color: #7E9D00;
      }
    }
  }
  .request-error {
    font-family: MacPawFixelMedium;
    color: #FF5656;
  }
  .log-in {
    display: flex;
    margin-top: 100px;
    align-self: center;
    font-family: MacPawFixelMedium;
    font-size: 16px;
    p {
      margin: 0 0 0 5px;
      text-decoration: underline;
      cursor: pointer;
      color: #7E9D00;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.reg-wrapper {
  opacity: 1;
  animation: fadeIn .25s ease-in-out;
}