.settings{
  align-self: start;
  position: relative;
  width: 100%;
  .header{
    width: 100%;
    left: 0;
    top: 0;
    border-bottom: 1px solid #E8E8E8;
    height: 80px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    .content-box{
      align-self: center;
      max-width: 1755px;
      margin: 0px 0px 0px 6vw;
      width: 70vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .route-status{
        display: flex;
        align-items: center;
        span{
          font-size: 16px;
          font-family: MacpawFixelMedium;
          margin: 2% 0px 0px 10px;
        }
        img{
          width: 18px;
          height: 18px;
        }
      }
      button{
        width: 160px;
        height: 45px;
        background-color: #7E9D00;
        border-radius: 10px;
        border: none;
        display: flex;
        padding: 10px 20px;
        align-items: center;
        justify-content: space-between;
        span{
          font-size: 14px;
          color: #ffffff;
          font-family: MacpawFixelMedium;
          margin-top: 1%;
        }
        img{
          width: 14px;
          height: 6px;
        }
      }
    }
  }
  .settings-content{
    margin-top: 80px;
    margin-left: 17.1vw;
    display: flex;
    flex-direction: column;
    padding: 40px 30px;
    box-sizing: border-box;
    .settings-title{
      text-align: left;
      h1{
        color: var(--main-palette-dino-dark, #2A2B3B);
        font-family: MacPawFixelBold;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 41.6px */
        margin-bottom: 15px;
        margin-top: 0;
      }
      p{
        color: var(--main-palette-dino-dark, #2A2B3B);
        font-family: MacPawFixelMedium;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.7;
        margin: 0;
      }
    }
    .tabs-wrapper{
      margin-top: 40px;
      .tab-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        .tabs{
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid var(--grey-10, #EAEAEC);
          .tab {
            width: 33.33%;
            cursor: pointer;
            padding: 5px 10px 15px 10px;
            &:hover {
              background-color: #f0f0f0;
            }

            &.active {
              border-bottom: 2px solid #2A2B3B;
            }
          }
        }
        .tab-content {
          .user-info{
            .main-info, .change-pass{
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 40px 0;
              box-sizing: border-box;
              border-bottom: 1px solid var(--grey-10, #EAEAEC);
              .info-title{
                text-align: left;
                h1{
                  color: var(--main-palette-dino-dark, #2A2B3B);
                  font-family: MacPawFixelBold;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 130%; /* 31.2px */
                }
                p{
                  color: var(--main-palette-dino-dark, #2A2B3B);
                  font-family: MacPawFixelMedium;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  opacity: 0.7;
                  margin: 0;
                }
              }
              .input-box{
                max-width: 560px;
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
                  label{
                    font-family: MacPawFixelMedium;
                    color: #2A2B3B;
                    font-size: 14px;
                    margin: 5px 0 5px 20px;
                  }
                  input{
                    width: 100%;
                    padding: 10px 20px;
                    height: 44px;
                    box-sizing: border-box;
                    font-size: 14px;
                    border: 1.5px solid #D4D5D8;
                    border-radius: 10px;
                    font-family: MacPawFixelMedium;
                    line-height: 25px;
                    outline: #7E9D00;
                  }:focus{
                     border: 1.5px solid #7E9D00;
                   }
                  input::placeholder{
                    font-family: MacPawFixelRegular;
                    font-size: 14px;
                    opacity: 0.3;
                  }
              }
            }
            .change-pass{
              border-bottom: 1px solid var(--grey-10, #EAEAEC);
              .change-pass-title{
                align-self: flex-start;
                text-align: left;
                h1{
                  color: var(--main-palette-dino-dark, #2A2B3B);
                  font-family: MacPawFixelBold;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 130%; /* 41.6px */
                  margin-bottom: 15px;
                  margin-top: 0;
                }
                p{
                  color: var(--main-palette-dino-dark, #2A2B3B);
                  font-family: MacPawFixelMedium;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  opacity: 0.7;
                  margin: 0;
                }
              }
              .inputs-container{
                width: 560px;
                gap:20px;
                .input-pass-box{
                  margin: 20px 0;
                }
                .save-btn{
                  margin-top: 10px;
                  width: 100%;
                  border-radius: 10px;
                  background: var(--main-palette-green, #7E9D00);
                  border: none;
                  height: 44px;
                  padding: 10px 20px;
                  box-sizing: border-box;
                  div{
                    font-family: MacPawFixelMedium;
                    color: #FFFFFF;
                  }
                }
              }
            }
            .email-auth{
              display: flex;
              justify-content: space-between;
              align-items: center;
              .disable2FA{
                display: flex;
                align-items: center;
                span{
                  font-family: MacPawFixelMedium;
                  margin-right: 20px;
                  font-size: 16px;
                  display: flex;
                  align-items: center;
                  img{
                    margin-right: 10px;
                  }
                }
              }
              .auth-title{
                text-align: left;
                h1{
                  color: var(--main-palette-dino-dark, #2A2B3B);
                  font-family: MacPawFixelBold;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 130%; /* 31.2px */
                }
                p{
                  color: var(--main-palette-dino-dark, #2A2B3B);
                  font-family: MacPawFixelMedium;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  opacity: 0.7;
                  margin: 0;
                }
              }
              .disable{
                width: 205px;
                height: 44px;
                padding: 10px 20px;
                box-sizing: border-box;
                border-radius: 10px;
                border: 1px solid #7E9D00;
                cursor: pointer;
                background: transparent;
                animation: fadeIn .25s ease-in-out;
                div{
                  font-family: MacPawFixelMedium;
                  font-size: 14px;
                  color: #2a2b3b;
                }
              }
              .enable{
                width: 205px;
                height: 44px;
                padding: 10px 20px;
                box-sizing: border-box;
                border-radius: 10px;
                border: none;
                cursor: pointer;
                background: #7E9D00;
                animation: fadeIn .25s ease-in-out;
                div{
                  font-family: MacPawFixelMedium;
                  font-size: 14px;
                  color: #FAFAFA;
                }
              }
              @keyframes fadeIn {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              }
              .input-email{
                opacity: 1;
                animation: fadeIn .25s ease-in-out;
                display: flex;
                align-items: center;
                max-width: 560px;
                width: 100%;
                .input-box{
                  label{
                    display: none;
                  }
                  input{
                    height: 54px;
                  }
                }
                button{
                  width: 138px;
                  height: 44px;
                  padding: 10px 20px;
                  box-sizing: border-box;
                  border-radius: 10px;
                  cursor: pointer;
                  div{
                    font-family: MacPawFixelMedium;
                    font-size: 14px;
                  }
                }
                .cancel{
                  margin: 0 10px;
                  border: 1.5px solid #BFCE80;
                  div{
                    color: #2A2B3B;
                  }
                }
                .save{
                  border: none;
                  background: #7E9D00;
                  div{
                    color: #FAFAFA;
                  }
                  &:disabled{
                    opacity: 0.5;
                  }
                }
              }
            }
          }
          .company-info{
            width: 100%;
            overflow-y: hidden;
            position: relative;
            .company-address{
              height: 450px;
              align-items: flex-start!important;
            }
            .company-des, .target-audience-des, .company-address{
              gap: 30px;
            }
            .company-name, .company-des, .target-audience-des, .company-address{
              display: flex;
              align-items: flex-start;
              padding: 40px 0;
              border-bottom: 1px solid var(--grey-10, #EAEAEC);
              gap: 30px;
              .name-title{
                text-align: left;
                max-width: 48%;
                width: 100%;
                h1{
                  color: var(--main-palette-dino-dark, #2A2B3B);
                  font-family: MacPawFixelBold;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 130%; /* 41.6px */
                  margin-bottom: 15px;
                  margin-top: 0;
                }
                p{
                  color: var(--main-palette-dino-dark, #2A2B3B);
                  font-family: MacPawFixelMedium;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  opacity: 0.7;
                  margin: 0;
                }
              }
              textarea{
                border: none;
                border-radius: 10px;
                background: var(--main-palette-white, #FAFAFA);
                max-width: 50%;
                width: 100%;
                padding: 22px 20px;
                min-height: 324px;
                box-sizing: border-box;
                font-size: 14px;
                font-family: MacPawFixelMedium;
                color: #2A2B3B;
                outline: none;
                resize: none;
              }
              .input-box{
                label{
                  display: none;
                }
                input{
                  width: 322px;
                }
              }
              .dropdowns{
                max-width: 52%;
                width: 100%;
                height: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 10px;
                right: 0;
                bottom: 80px;
                .styles_container__RdIoP{
                  max-width: 48%;
                  width: 100%;
                }
                ._1Lxpd{
                  max-width: 50%;
                  width: 100%;
                  box-sizing: border-box;
                  ._30Ipg{
                    width: 100%;
                    box-sizing: border-box;
                  }
                  ._1KMXW{
                    margin: 0;
                    overflow: visible;
                    z-index: 222;
                    svg{
                      display: none;
                    }
                    ._3LhE5{
                      margin: 0;
                      padding: 10px;
                      border-bottom: 1px solid #d7d7d7;
                    }
                  }
                }
                .input-box{
                  label{
                    display: none;
                  }
                  input{
                    width: 100%;
                    border: 1px solid #EAEAEC;
                  }
                  max-width: 48%;
                  width: 100%;
                }
              }
            }
          }
          .domain-wrapper{
            width: 100%;
            overflow-y: hidden;
            display: flex;
            align-items: center;
            padding: 30px 0;
            .domain-title{
              max-width: 560px;
              width: 100%;
              text-align: left;
              h1{
                color: var(--main-palette-dino-dark, #2A2B3B);
                font-family: MacPawFixelBold;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%; /* 31.2px */
              }
              p{
                color: var(--main-palette-dino-dark, #2A2B3B);
                font-family: MacPawFixelMedium;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                opacity: 0.7;
                margin: 0;
              }
            }
            button{
              width: 262px;
              margin-left: 30px;
              border-radius: 10px;
              background: var(--main-palette-green, #7E9D00);
              border: none;
              height: 44px;
              padding: 10px 20px;
              box-sizing: border-box;
              cursor: pointer;
              div{
                font-family: MacPawFixelMedium;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
}