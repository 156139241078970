.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 90%;
  .login {
    align-items: center;
    position: relative;
    width: 400px;
    .mark {
      width: 155px;
      height: 155px;
      top: -5%;
      bottom: 10vh;
      position: absolute;
      right: 0;
    }
    .logo {
      display: flex;
      align-items: center;
      margin-left: 20px;
      img {
        width: 120px;
        height: 34px;
      }
    }
    .form-title {
      margin-left: 20px;
      font-family: MacPawFixelBold;
      font-size: 32px;
      color: #2A2B3B;
      text-align: left;
      width: 300px;
    }
    .form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      width: 400px;
      margin-top: 20px;
      .email {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        .email-span {
          font-family: MacPawFixelMedium;
          color: #2A2B3B;
          font-size: 14px;
          margin: 5px 0 5px 20px;
        }
        .email-input {
          width: 100%;
          padding: 10px 20px;
          box-sizing: border-box;
          font-size: 14px;
          height: 44px;
          border: 1.5px solid #D4D5D8;
          border-radius: 10px;
          font-family: MacPawFixelMedium;
          line-height: 25px;
          outline: #7E9D00;
        }
        :focus {
           border: 1.5px solid #7E9D00;
        }
        .email-input::placeholder {
          font-family: MacPawFixelRegular;
          font-size: 14px;
          opacity: 0.3;
        }
        .clear-area {
          position: absolute;
          right: 5%;
          width: 25px;
          height: 25px;
          bottom: 15%;
          cursor: pointer;
        }
        .x-hide {
          display: none;
        }
      }
      .pass {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 100%;
        .pass-description {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: MacPawFixelMedium;
          label {
            font-size: 14px;
            color: #2A2B3B;
            margin: 5px 0 5px 20px;
          }
          div {
            cursor: pointer;
            font-size: 14px;
            text-decoration: underline;
          }
        }
        .pass-input {
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: column;
          .eye {
            position: absolute;
            right: 2vh;
            top: 1vh;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .pass-field:focus{
             border: 1.5px solid #7E9D00;
           }
          .pass-field, .pass-field-alt{
            border: 1.5px solid #D4D5D8;
            height: 44px;
            border-radius: 10px;
            width: 100%;
            box-sizing: border-box;
            padding: 10px 20px;
            font-family: MacPawFixelMedium;
            font-size: 14px;
            line-height: 25px;
            outline: #7E9D00;
          }
          input::placeholder {
            display: flex;
            align-items: center;
            opacity: 0.3;
            font-size: 14px;
            font-family: MacPawFixelRegular;
          }
        }
        .error-show {
          display: flex;
          color: red;
          font-family: MacPawFixelRegular;
          margin: 0px 0px 0px 20px;
        }
        .error-hide {
          display: none;
        }
        .radio-btn {
          display: flex;
          margin: 15px 0 0 20px;
          font-size: 12px;
          font-family: MacPawFixelRegular;
          line-height: 17px;
          span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            font-size: 12px;
            font-family: MacPawFixelMedium;
            padding: 0;
            margin-top: 3px;
          }
          .custom-checkbox {
            // Hide the default checkbox appearance
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 16px;
            height: 16px;
            background-color: white;
            border: 2px solid #7E9D00;
            border-radius: 5px;
            display: inline-block;
            position: relative;
            transition: background-color 0.2s, border-color 0.2s;
            cursor: pointer;
            &:checked {
              background-color: #7E9D00; // Change to your desired background color
              border: 2px solid #7E9D00; // Change to your desired border color
            }
            // Custom checkbox indicator (default checkmark)
            &::after {
              content: '';
              width: 3px;
              height: 6px;
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              position: absolute;
              top: 1.2%;
              left: 27%;
              display: none;
            }

            &:checked::after {
              display: block;
            }
          }
        }
      }
      .sign-in {
        display: flex;
        margin: 20px 0 20px;
        cursor: pointer;
        width: 100%;
        background: #7E9D00;
        box-sizing: border-box;
        height: 44px;
        border: none;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border-radius: 10px;
        span {
          color: #F2F2F2;
          font-family: MacPawFixelMedium;
        }
        :disabled {
          span {
            opacity: 0.5;
          }
        }
      }
      .sign-in:hover {
        opacity: 0.8;
      }
      .sign-disabled {
        opacity: 0.5;
        display: flex;
        padding: 10px 20px;
        height: 44px;
        box-sizing: border-box;
        margin: 20px 0 20px;
        cursor: pointer;
        width: 100%;
        background: #C7C7C7;
        border: none;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        span {
          color: #F2F2F2;
          font-family: MacPawFixelMedium;
        }
      }
    }
  }
  .sign-up{
    width: 100%;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    align-self: center!important;
    display: flex;
    font-family: MacPawFixelMedium;
    margin-top: 100px;
    p{
      font-size: 16px;
      margin: 0 0 0 5px;
      text-decoration: underline;
      cursor: pointer;
      color: #7E9D00;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.login-wrapper {
  opacity: 1;
  animation: fadeIn .25s ease-in-out;
}