.circle-chart-component{
  display: flex;
  align-items: center;
  margin-top: 40px;
  background: #fafafa;
  justify-content: space-between;
  border-radius: 30px;
  .circle-stats{
    width: 100%;
    justify-content: space-between;
    padding: 30px 10px;
    display: flex;
    align-items: center;
    #container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .highcharts-point.highcharts-color-0{
        fill: #ff5656;
      }
    }
    .circle-statistic{
      height: 100%;
      margin-left: 60px;
      .outer-circle-des{
        display: flex;
        align-items: center;
        .line1{
          width: 22px;
          height: 4px;
          border-radius: 3px;
          background: var(--Main-palette-Green, #7E9D00);
        }
        .outer-text{
          margin-left: 11px;
          h5{
            margin:0;
            color: var(--Grey-50, #95959D);
            font-family: MacPawFixelBold;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%; /* 15.4px */
          }
          p{
            color: var(--Main-palette-Dino_Dark, #2A2B3B);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: MacPawFixelBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%; /* 26.4px */
            margin: 7px 0 0 0;
          }
        }
      }
      .inner-circle-des{
        display: flex;
        align-items: center;
        margin-top: 30px;
        .line2{
          width: 22px;
          height: 4px;
          border-radius: 3px;
          background: var(--Main-palette-Green, #FF5656);
        }
        .inner-text{
          margin-left: 11px;
          h5{
            margin:0;
            color: var(--Grey-50, #95959D);
            font-family: MacPawFixelBold;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%; /* 15.4px */
          }
          p{
            color: var(--Main-palette-Dino_Dark, #2A2B3B);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: MacPawFixelBold;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%; /* 26.4px */
            margin: 7px 0 0 0;
          }
        }
      }
    }
    .hr{
      background: var(--Main-palette-White, #FAFAFA);
      display: flex;
      box-sizing: border-box;
      width: 1px;
      height: 217px;
      margin: 0 75px;
    }
    .statistic-description{
      max-width: 500px;
      width: 100%;
      padding-right: 40px;
      h3{
        color: var(--Main-palette-Dino_Dark, #2A2B3B);
        font-family: MacPawFixelBold;
        font-size: 24px;
        font-weight: 700;
        line-height: 110%; /* 26.4px */
        text-align: left;
      }
      div{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        p{
          color: var(--Grey-50, #95959D);
          font-family: MacPawFixelMedium;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 110%; /* 15.4px */
        }
        span{
          color: var(--Main-palette-Dino_Dark, #2A2B3B);
          text-align: right;
          font-family: MacPawFixelMedium;
          font-size: 14px;
          font-weight: 500;
          line-height: 110%; /* 15.4px */
        }
      }
    }
  }
}