button{
  display: flex;
  background: transparent;
  width: 160px;
  height: 44px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1.5px solid #bfce80;
  font-family: MacPawFixelMedium;
  cursor: pointer;
  &:disabled {
    border: 1.5px solid #95959d;
  }
}
