.pg-component-wrapper{
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border-radius: 20px;
  background: var(--Main-palette-White, #FAFAFA);
  width: 50%;
  .pg-title{
    color: var(--Main-palette-Dino_Dark, #2A2B3B);
    text-align: right;
    font-family: MacPawFixelBold, 'sans-serif';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 17.6px */
  }
  .bar-box{
    width: 100%;
    .bar-element{
      margin: 30px 0;
      .pg-desc{
        display: flex;
        justify-content: space-between;
        .pg-decs-title{
          text-align: left;
          font-family: MacPawFixelMedium, 'sans-serif';
          color: var(--Main-palette-Dino_Dark, #2A2B3B);
          .type-message{
            font-size: 14px;
          }
          .date{
            font-size: 12px;
            opacity: 0.5;
          }
        }
        .value{
          align-self: flex-end;
        }
      }
    }
  }
}