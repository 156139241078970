/* add css module styles here (optional) */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.container {
  width: 273px;
  height: 44px;
  position: relative;
  font-family: MacPawFixelMedium;
}

.dropdown {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0);
  border-radius: 10px;
  border: 1px solid #EAEAEC;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
}

.dropdown_items_wrapper {
  position: relative;
  margin-top: 5px;
  transition: all 0.5s;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  border-radius: 10px;
}
.search_icon{
  position: absolute;
  left: 20px;
}

.dropdown_items {
  padding: 10px;
  height: 346px;
  overflow-y: scroll;
}

.input_wrapper {
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
}

.country_search {
  outline: none;
  border: none;
  border-radius: 10px;
  background: #f4f4f5;
  padding: 5px 10px 5px 40px;
  height: 36px;
  box-sizing: border-box;
  width: 100%;
}

.country_search::placeholder {
  font-family: 'Poppins';
  font-size: 13px;
  color: rgb(168, 168, 168);
}

/* width */
.dropdown_items::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.dropdown_items::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.dropdown_items::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

/* Handle on hover */
.dropdown_items::-webkit-scrollbar-thumb:hover {
  background: #696969;
}


.dropdown_item {
  margin-bottom: 20px;
  display: flex;
  cursor: pointer;
}

.dropdown_item_title {
  margin-left: 15px;
  margin-top: -2px;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  color: #484848;
}

.selected_country {
  font-size: 15px;
  font-weight: 500;
  color: #484848;
  margin-left: 15px;
}

.country_flag {
  height: 15px;
  width: 16px;
  border-radius: 50%;
}

.styles_container__KyKjt{
  width: 273px;
}
